import { ContextType } from 'types/notifications'
import { chatThreadEventUpdatedDataType } from '../types'

export const omniCenterChatThreadEventUpdatedHandler = (
  context: ContextType,
  data: chatThreadEventUpdatedDataType,
) => {
  const { chatDetail, setChatDetail, chatStore } = context

  if (chatDetail?.id === `${data.chat.id}`) {
    const thread = chatDetail.threads.find(
      t => `${t.id}` === `${data.chatThreadEvent.chatThreadId}`,
    )
    if (thread) {
      const threadEvent = thread.events.find(
        threadE => threadE.uuid === `${data.chatThreadEvent.uuid}`,
      )

      if (threadEvent) {
        // Replace the old value with the new one
        thread.events.splice(thread.events.indexOf(threadEvent), 1, data.chatThreadEvent)
        setChatDetail({
          ...chatDetail,
        })
      } else {
        // Event not found in the thread
      }
    } else {
      // Thread was not found
    }
  } else {
    // Invalidate the data from local cache
    chatStore.invalidate(data.chat.id)
  }
}
