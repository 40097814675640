import React, { useState, useEffect, useContext, createContext } from 'react'
import { getToken } from 'store/utils'
import actioncable from './action-cable-react-jwt'

export const StoreContext = createContext({})

const CABLE_URL = process.env.REACT_APP_ACTION_CABLE_URL

export const IntercomunicationContext = ({ children }) => {
  const [cable, setCable] = useState()

  useEffect(() => {
    const token = getToken()
    const c = actioncable.createConsumer(CABLE_URL, token)
    setCable(c)
    return () => {
      if (c) {
        c.disconnect()
      }
    }
  }, [])

  const subscribe = (channel, actions) => {
    if (!cable) {
      console.error('Intercomunication: The connection was not stablish yet')
      return null
    }
    return cable.subscriptions.create({ channel }, actions)
  }

  return (
    <StoreContext.Provider value={{ subscribe }}>{cable ? children : null}</StoreContext.Provider>
  )
}
const useIntercomunication = () => useContext(StoreContext)

export default useIntercomunication
