import { get } from 'lodash'
import DESIGNS from 'utils/designs'
import { propertiesToCamel, propertiesToCamelDepth } from '../utils'
import { SECTIONS, SECTIONS_DESIGNS } from './config'

const parseWorkspace = workspace => {
  return {
    id: +workspace.id,
    name: workspace.attributes.name,
  }
}

export const ALL_WORKSPACES = {
  id: -10000,
  name: 'Todos',
}

export const parseUser = user => {
  let account

  if (user.attributes.account && user.attributes.account.data) {
    account = {
      id: user.attributes.account.data.id,
      name: user.attributes.account.data.attributes.name,
      status: user.attributes.account.data.attributes.status,
      workspaces: (user.attributes.account.data.attributes.workspaces || []).map(parseWorkspace),
      knowledgeBaseStats: user.knowledgeBaseStats,
    }
  }

  const parsedUser = {
    account,
    avatarThumbUrl: user.attributes.avatar_thumb_url,
    chatterId: get(user, 'attributes.chatter.data.id'),
    designId: user.attributes.ui_type || DESIGNS.YODA,
    displayName: user.attributes.display_name,
    email: user.attributes.email,
    firstName: user.attributes.first_name,
    id: user.id,
    isChatter: user.attributes.is_chatter,
    isClient: user.attributes.is_client,
    isSuperadmin: user.attributes.is_superadmin,
    lastName: user.attributes.last_name,
    locale: user.attributes.locale,
    permissions: parsePermissions(user.attributes.global_permissions),
    status: user.attributes.status,
    timeZone: user.attributes.time_zone,
    userName: user.attributes.userName,
  }

  return parsedUser
}

export const getMenuData = (designId, features, translations) => {
  let menuData = []

  if (get(features, 'accounts.canAccess')) {
    menuData.push({
      title: translations.accounts,
      key: SECTIONS.ACCOUNTS,
      icon: 'fa fa-user-circle',
      url: '/accounts',
    })
  }

  if (get(features, 'workspaces.canAccess')) {
    menuData.push({
      title: 'Workspaces',
      key: SECTIONS.WORKSPACES,
      url: '/workspaces',
    })
  }

  /* eslint-disable-next-line */
  if (get(features, 'accounts.canAccess') && false) {
    // TODO use the correct flag
    menuData.push({
      title: translations.capacityPlanning,
      key: SECTIONS.CAPACITY_PLANNING,
      icon: 'fa fa-sitemap',
      url: '/capacity-planning',
    })
  }

  /* eslint-disable-next-line */
  if (get(features, 'accounts.canAccess') && false) {
    // TODO use the correct flag
    menuData.push({
      title: translations.myPlan,
      key: SECTIONS.MY_PLAN,
      icon: 'fa fa-rocket',
      url: '/my-plan',
    })
  }

  /* eslint-disable-next-line */
  if (get(features, 'accounts.canAccess') && false) {
    // TODO use the correct flag
    menuData.push({
      title: translations.accounts,
      key: SECTIONS.WORKSPACE_GROUP,
      icon: 'fa fa-object-group',
      url: '/workspace-group',
    })
  }

  if (get(features, 'omniCenter.canAccess')) {
    menuData.push({
      title: translations.supervisor,
      key: SECTIONS.SUPERVISOR,
      icon: 'icmn-bubbles chat-center-menu-option',
      url: '/supervisor',
    })
  }

  if (get(features, 'chats.canAccess')) {
    menuData.push({
      title: translations['omnicenter.title.label'],
      key: SECTIONS.OMNICENTER,
      icon: 'icmn-bubbles chat-center-menu-option',
      url: '/chats',
    })
  }

  if (get(features, 'myTeam.canAccess')) {
    menuData.push({
      title: translations.myTeam,
      key: SECTIONS.MY_TEAM,
      icon: 'icmn-bubbles chat-center-menu-option',
      url: '/workspace-configuration',
    })
  }

  if (get(features, 'marketing.canAccess')) {
    menuData.push({
      title: translations.marketing,
      key: SECTIONS.MARKETING,
      url: '/marketing',
    })
  }

  if (get(features, 'knowledgeBase.canAccess')) {
    menuData.push({
      title: translations.menuKnowledgeBase,
      key: SECTIONS.KNOWLEDGE_BASE,
      icon: 'icmn-database chat-bdc-menu-option',
      url: '/knowledge',
    })
  }

  if (get(features, 'leads.canAccess')) {
    menuData.push({
      title: translations.opportunities,
      key: SECTIONS.OPPORTUNITIES,
      icon: 'fa fa-eye',
      url: '/opportunities',
    })
  }

  if (get(features, 'tickets.canAccess')) {
    menuData.push({
      title: translations.tickets,
      key: SECTIONS.TICKETS,
      url: '/tickets',
    })
  }

  if (get(features, 'analytics.canAccess')) {
    menuData.push({
      title: translations.analytics,
      key: SECTIONS.ANALYTICS,
      icon: 'fa fa-line-chart',
      url: '/analytics',
    })
  }

  if (get(features, 'accountSettings.canAccess')) {
    menuData.push({
      title: translations.configuration,
      key: SECTIONS.LEGACY_CONFIGURATION,
      icon: 'fa fa-gear',
      url: '/config',
    })
  }

  if (get(features, 'accountSettings.canAccess')) {
    menuData.push({
      title: translations.configuration,
      key: SECTIONS.CONFIGURATION,
      url: '/configuration',
    })
  }

  // Remove the items that does not below to the current design
  menuData = menuData.filter(item => SECTIONS_DESIGNS[designId].includes(item.key))

  return menuData
}

export const parsePermissions = permissions => {
  const menuData = propertiesToCamelDepth(permissions)

  return menuData
}

export const parseAccount = data => {
  return {
    id: data.id,
    name: data.attributes.name,
  }
}

export const parseStats = stats => {
  return {
    id: stats.id,
    chatsCount: stats.attributes.valid_chats_count,
    messagesCount: stats.attributes.valid_chat_messages_count,
  }
}

export const parseSettings = settings => {
  const resp = {}
  if (settings.livechat) {
    resp.livechat = {
      id: settings.id,
      licenseId: settings.livechat.license_id,
      groupIds: settings.livechat.group_ids,
    }
  }

  if (settings.meli) {
    resp.meli = {
      enabled: settings.meli.enabled,
      preSaleAnswerSignature: settings.meli.pre_sale_answer_signature,
    }
  }

  if (settings.facebook) {
    resp.facebook = {
      enabled: settings.facebook.enabled,
      fbUserId: settings.facebook.fb_user_id,
    }
  }

  return resp
}

export const parseKnowledgeBaseStats = data => {
  return {
    id: data.id,
    ...propertiesToCamel(data.attributes),
  }
}
