import { USER_EVENTS } from './events'

function gtag() {
  // eslint-disable-next-line
  window.dataLayer.push(arguments)
}
gtag('js', new Date())
gtag('config', process.env.REACT_APP_GOOGLE_ANALYTICS)

const gevent = (eventName, data) => gtag('event', eventName, data)

export const useGoogleAnalytics = () => {
  return {
    gtag,
    gevent,
    USER_EVENTS,
  }
}
