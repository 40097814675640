import { notification } from 'antd'
import { APPLICATION_EVENTS } from 'enums/notifications'
import React, { createContext, useContext, ReactChild, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import useIntercomunication from 'store/intercomunication/hook'
import { ACTIONS } from 'store/intercomunication/utils'
import { formatBackendResponse } from 'store/utils'

const CABLE_CHANNEL_NAME = 'NotificationsChannel'

type ActionHandlersType = { [key in typeof ACTIONS[keyof typeof ACTIONS]]: (data?: any) => void }

const ACTIONS_HANDLERS: ActionHandlersType = {
  [ACTIONS.RECIVED]: () => {},
  [ACTIONS.DISCONECTED]: () => {},
  [ACTIONS.REJECTED]: () => {},
  [ACTIONS.CONNECTED]: () => {},
}

type ApplicationHookType = [
  {},
  {
    actionSubscribe: (action: NotificationActionType, callback: (data: any) => void) => () => void
  },
]

const initialState: ApplicationHookType = [
  {},
  {
    actionSubscribe: () => () => {},
  },
]

export const StoreContext = createContext<ApplicationHookType>(initialState)

type ApplicationContextProps = {
  children: ReactChild
}

type NotificationActionType = typeof APPLICATION_EVENTS[keyof typeof APPLICATION_EVENTS]

type NotificationChannelMessageType = {
  uuid: string
  action: NotificationActionType
  resource: any
}

type ActionSubcriptionsType = { [key in NotificationActionType]: Array<(data: any) => void> }

const intialSubscriptionValue = Object.values(APPLICATION_EVENTS).reduce(
  (acc, actionValue) => ({
    ...acc,
    [actionValue]: [],
  }),
  {} as ActionSubcriptionsType,
)

export const ApplicationContext = ({ children }: ApplicationContextProps) => {
  const intl = useIntl()
  // @ts-ignore
  const { subscribe: actionCableSubscribe } = useIntercomunication()
  const actionSubscriptions = useRef<ActionSubcriptionsType>(intialSubscriptionValue)
  const [isConnected, setIsConnected] = useState(false)

  useEffect(() => {
    if (!actionCableSubscribe) {
      return () => {}
    }
    ACTIONS_HANDLERS[ACTIONS.CONNECTED] = () => {
      setIsConnected(true)
    }

    ACTIONS_HANDLERS[ACTIONS.DISCONECTED] = args => {
      setIsConnected(false)
      if (args.event.reason === 'Server restart') {
        // the server has been restarted, it might be a new deploy
        notification.info({
          message: intl.formatMessage({ id: 'serverHasRestarted' }),
          description: intl.formatMessage({ id: 'serverHasRestartedDesc' }),
        })
      } else if (args.event.reason === 'Auth Error') {
        // if the reason is "Auth Error" it means that the server is not yet ready to accept
        // incomming request, so do nothing.
      } else {
        notification.warning({
          message: intl.formatMessage({ id: 'connectionProblem' }),
          description: intl.formatMessage({ id: 'connectionProblemDesc' }),
        })
      }
    }

    ACTIONS_HANDLERS[ACTIONS.REJECTED] = () => {
      setIsConnected(false)
    }

    const channelInstance = actionCableSubscribe(CABLE_CHANNEL_NAME, {
      connected: () => ACTIONS_HANDLERS[ACTIONS.CONNECTED](),
      disconnected: (args: any) => ACTIONS_HANDLERS[ACTIONS.DISCONECTED](args),
      rejected: () => ACTIONS_HANDLERS[ACTIONS.REJECTED](),
      received: (data: any) => {
        channelInstance.perform('ack', { uuid: data.uuid })
        const parsedData = formatBackendResponse(data)
        onRecibeMessage(parsedData)
      },
    })

    // Used for test e2e
    // @ts-ignore
    window.notificationChannelEventSimulator = channelInstance.received
    return () => {
      channelInstance.unsubscribe()
    }
  }, [actionCableSubscribe])

  const onRecibeMessage = (data: NotificationChannelMessageType) => {
    const actions = actionSubscriptions.current[data.action]
    if (!actions) {
      console.warn(`The action "${data.action}" is not supported yet.`)
      return
    }
    actions.forEach(action => {
      action(data)
    })
  }

  // Update the status indicator in the left menu
  useEffect(() => {
    // wait for the page to load
    setTimeout(() => {
      const badgeRef = document.querySelector('#badgeConnectionStatus sup')
      if (badgeRef) {
        badgeRef.classList.remove(`ant-badge-status-${isConnected ? 'error' : 'success'}`)
        badgeRef.classList.add(`ant-badge-status-${isConnected ? 'success' : 'error'}`)
      }
    }, 300)
  }, [isConnected])

  const actionSubscribe = (action: NotificationActionType, callback: (data: any) => void) => {
    actionSubscriptions.current[action].push(callback)

    // returns the function to unsubscribe
    return () => {
      const indexToRemove = actionSubscriptions.current[action].indexOf(callback)
      if (indexToRemove === -1) {
        console.warn('The method to unsubscribe was not found')
        return
      }
      actionSubscriptions.current[action].splice(indexToRemove, 1)
    }
  }

  return <StoreContext.Provider value={[{}, { actionSubscribe }]}>{children}</StoreContext.Provider>
}
const useApplication = () => useContext(StoreContext)

export default useApplication
