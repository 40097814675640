import React from 'react'
import { Button } from 'antd'
import { LinkOutlined } from '@ant-design/icons'
import { useGoogleAnalytics } from 'store/googleAnalytics/hook'

const NewChatNotification = ({ title, chatId }) => {
  const { gevent, USER_EVENTS } = useGoogleAnalytics()
  const onNotificationButtonClick = () => {
    gevent(USER_EVENTS.NOTIFICATION_NEW_MESSAGE_BUTTON_CLICK)
  }
  return (
    <a href={`/#/chats/${chatId}`} onClick={onNotificationButtonClick}>
      <Button block type="primary" icon={<LinkOutlined />}>
        {title}
      </Button>
    </a>
  )
}

export default NewChatNotification
