import moment from 'moment'

// eslint-disable-next-line
export const DATE_OPTIONS = {
  TODAY: 'TODAY',
  LAST_WEEK: 'LAST_WEEK',
  LAST_30_DAYS: 'LAST_30_DAYS',
  CUSTOM: 'CUSTOM',
}

export const relativeToAbsoluteDatesMapper = {
  [DATE_OPTIONS.TODAY]: () => {
    return [
      moment()
        .startOf('day')
        .unix(),
      moment()
        .endOf('day')
        .unix(),
    ]
  },
  [DATE_OPTIONS.LAST_WEEK]: () => {
    return [
      moment()
        .subtract(7, 'days')
        .unix(),
      moment().unix(),
    ]
  },
  [DATE_OPTIONS.LAST_30_DAYS]: () => {
    return [
      moment()
        .subtract(30, 'days')
        .unix(),
      moment().unix(),
    ]
  },
}
