import { toCamel } from 'store/utils'
import { ChatListType } from 'types/chat'
import { ContextType } from 'types/notifications'
import { ChatterGroupType, ChatterType } from 'types/users'
import {
  chatAssignedDataType,
  chatClosedDataType,
  chatOpenDataType,
  chatUnassignedDataType,
} from './types'

export const updateChatAssigneeHandler = ({
  chatter,
  chatterGroup,
  unassigned,
}: {
  chatter?: ChatterType
  chatterGroup?: ChatterGroupType
  unassigned: boolean
}) => async (context: ContextType, data: chatAssignedDataType | chatUnassignedDataType) => {
  const {
    chats,
    setChats,
    chatDetail,
    setChatDetail,
    filters,
    sort,
    chatStore,
    setAssignmentInProgress,
  } = context

  const chatListItem = chats.find(chat => chat.id === `${data.chat.id}`)

  // This enables the assignee control again
  setAssignmentInProgress(false)

  if (
    (unassigned && filters.unassigned) ||
    (chatter && chatter?.id === filters.assignee_chatter_id) ||
    (!filters.assignee_chatter_id && !filters.unassigned)
  ) {
    // has to be shown
    if (!chatListItem) {
      // show
      // TODO: probably not needed to get extra data, remove the chatStore from the context as well
      const chatData = await chatStore.get(data.chat.id)
      setChats(sortChatList([...chats, (chatData as unknown) as ChatListType], sort))
    } else {
      // Only update the assignee
      chatListItem.assigneeChatter = chatter
      chatListItem.assigneeChatterGroup = chatterGroup
      setChats([...chats])
    }
  } else {
    // has to be hidden
    // eslint-disable-next-line no-lonely-if
    if (chatListItem) {
      // hide
      chats.splice(chats.indexOf(chatListItem), 1)
      setChats([...chats])
    } else {
      // do nothing
    }
  }

  if (chatDetail?.id === `${data.chat.id}`) {
    setChatDetail({
      ...chatDetail,
      // NOTE: if the backend sends some of the threads (instead of everything) update this line to extend the data
      threads: data.chat.threads,
      assigneeChatter: chatter,
      assigneeChatterGroup: chatterGroup,
      permissions: data?.chat?.permissions,
    })
  } else {
    // do nothing
  }
}

export const updateChatStatusHandler = (newStatus: 'CLOSED' | 'OPEN') => async (
  context: ContextType,
  data: chatClosedDataType | chatOpenDataType,
) => {
  const { chats, setChats, chatDetail, setChatDetail, filters, chatStore, sort } = context

  const chatListItem = chats.find(chat => chat.id === `${data.chat.id}`)

  const [currentStatus] = filters.status

  if (currentStatus === newStatus) {
    // The chat has to be showed
    if (!chatListItem) {
      // show
      const chatData = await chatStore.get(data.chat.id)
      setChats(sortChatList([...chats, (chatData as unknown) as ChatListType], sort))
    } else {
      // Only update the status
      chatListItem.status = newStatus
      setChats(sortChatList([...chats], sort))
    }
  } else {
    // The chat has to be hidden
    // eslint-disable-next-line no-lonely-if
    if (chatListItem) {
      // remove
      chats.splice(chats.indexOf(chatListItem), 1)
      setChats([...chats])
    } else {
      // do nothing
    }
  }

  if (chatDetail?.id === `${data.chat.id}`) {
    setChatDetail({
      ...chatDetail,
      status: newStatus,
      permissions: data.chat.permissions,
    })
  }
}

/**
 * Method to sort the chat list in the Frontend
 * This is used to keep the orden updated after recieved events via websockets
 */
export function sortChatList(chats: ChatListType[], sort: any) {
  return chats.sort((chatA, chatB) =>
    // @ts-ignore
    (chatA[toCamel(sort.column)] - chatB[toCamel(sort.column)]) *
      (sort.order === 'descend' ? -1 : 1) >
    0
      ? 1
      : -1,
  )
}
