import React from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import Loadable from 'react-loadable'

import Layout from 'layouts'
import Loader from 'components/layout/Loader'
import NotFoundPage from 'pages/system/404'
import useUser from 'store/users/hook'
import { IntercomunicationContext } from 'store/intercomunication/hook'
import { get } from 'lodash'
import DESIGNS from 'utils/designs'

import { OmniCenterContext } from 'store/omniCenter/hook'
import { ApplicationContext } from 'store/application/hook'
import { ChattersContext } from 'store/accountChatters/store'

const loadable = loader =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  })

const routes = [
  // Legacy design
  {
    path: '/analytics',
    Component: loadable(() => import('pages/analytics')),
    feature: 'analytics',
  },
  {
    path: '/accounts',
    Component: loadable(() => import('pages/yoda/accounts')),
    feature: 'accounts',
  },
  {
    path: '/knowledge',
    Component: loadable(() => import('pages/yoda/knowledgeBase')),
    feature: 'knowledgeBase',
  },
  {
    path: '/config/:sectionId?',
    designId: DESIGNS.YODA,
    Component: loadable(() => import('pages/yoda/configuration/MeliRedirect')),
    feature: 'accountSettings',
  },
  {
    path: '/opportunities',
    Component: loadable(() => import('pages/opportunities')),
    feature: 'leads',
  },
  {
    path: '/profile',
    Component: loadable(() => import('pages/profile')),
  },
  {
    path: '/capacity-planning',
    Component: loadable(() => import('pages/capacityPlanning')),
  },
  {
    path: '/my-plan',
    Component: loadable(() => import('pages/chatterMyPlan')),
  },
  {
    path: '/workspace-group',
    Component: loadable(() => import('pages/workspaceGroup')),
  },
  // Yoda design
  {
    path: '/chats/:chatId?',
    Component: loadable(() => import('pages/yoda/omnicenter')),
    designId: DESIGNS.YODA,
    feature: 'chats',
  },
  {
    path: '/workspace-configuration/:sectionId?/:workspaceId?/:userType?',
    Component: loadable(() => import('pages/yoda/workspacesConfiguration')),
    designId: DESIGNS.YODA,
    feature: 'myTeam', // TODO replace with the correct value
  },
  {
    path: '/marketing/:sectionId?/:itemId?',
    Component: loadable(() => import('pages/yoda/marketing')),
    designId: DESIGNS.YODA,
    feature: 'marketing',
  },
  {
    path: '/marketing-campaigns/:campaignId',
    Component: loadable(() => import('pages/yoda/marketingCampaigns')),
    designId: DESIGNS.YODA,
    feature: 'marketing',
  },
  {
    path: '/chatters/:chatterId',
    Component: loadable(() => import('pages/yoda/chatters')),
    designId: DESIGNS.YODA,
    feature: 'myTeam',
  },
  {
    path: '/user-profile/:sectionId?',
    Component: loadable(() => import('pages/yoda/userProfile')),
    designId: DESIGNS.YODA,
  },
  {
    path: '/tickets/:ticketId?',
    Component: loadable(() => import('pages/yoda/tickets')),
    designId: DESIGNS.YODA,
    feature: 'tickets',
  },
  {
    path: '/configuration/:sectionId?',
    Component: loadable(() => import('pages/yoda/configuration')),
    designId: DESIGNS.YODA,
    feature: 'accountSettings',
  },
  {
    path: '/workspaces/:workspaceId?',
    Component: loadable(() => import('pages/yoda/workspaces')),
    designId: DESIGNS.YODA,
    feature: 'workspaces',
  },
  // System Pages
  {
    path: '/system/500',
    Component: loadable(() => import('pages/system/500')),
    exact: true,
  },
  {
    path: '/system/login',
    Component: loadable(() => import('pages/system/login')),
    exact: true,
  },
  {
    path: '/system/forgot-password/:recoveryToken?',
    Component: loadable(() => import('pages/system/forgot-password')),
  },
  {
    path: '/system/404',
    Component: loadable(() => import('pages/system/404')),
    exact: true,
  },
  {
    path: '/system/onboarding',
    Component: loadable(() => import('pages/yoda/onboarding')),
    exact: true,
  },
]

const OmniCenterEnable = ({ children }) => {
  const [{ user }] = useUser()

  if (get(user, 'permissions.chats.canAccess')) {
    return <OmniCenterContext>{children}</OmniCenterContext>
  }
  return children
}

const IntercomunicationEnable = ({ children }) => {
  const [{ user }] = useUser()

  // check if the user is logged in before to connect the socket
  if (user?.id) {
    return <IntercomunicationContext>{children}</IntercomunicationContext>
  }
  return children
}

const renderAvailableRoutes = (routesPath, user) => {
  return routesPath
    .filter(route => {
      // if the designId is not defined or the route's design belows to the designId
      if (route.designId && route.designId !== get(user, 'designId')) {
        return false
      }

      if (!route.feature) {
        // route w/o restriction
        return true
      }
      if (!get(user, 'permissions')) {
        // the user was not loaded yet
        return false
      }
      return (user.permissions[route.feature] || {}).canAccess
    })
    .map(({ path, Component, exact }) => (
      <Route path={path} key={path} exact={exact} component={Component} />
    ))
}

const Router = () => {
  const [{ menu, user }] = useUser()

  return (
    <Layout>
      <IntercomunicationEnable>
        <ApplicationContext>
          <ChattersContext>
            <OmniCenterEnable>
              <Switch>
                {/* The default URL is the first element in the menu list */}
                <Route
                  exact
                  path="/"
                  render={() => {
                    return menu && menu.length ? <Redirect to={menu[0].url} /> : 'Loading...'
                  }}
                />
                {renderAvailableRoutes(routes, user)}
                <Route component={NotFoundPage} />
              </Switch>
            </OmniCenterEnable>
          </ChattersContext>
        </ApplicationContext>
      </IntercomunicationEnable>
    </Layout>
  )
}

export default Router
