import React, { createContext, useContext, useEffect, useState } from 'react'
import useApplication from 'store/application/hook'
import { APPLICATION_EVENTS } from 'enums/notifications'
import { USER_STATUS } from 'enums/userStatus'
import { ChatterType, UserAppStatusType } from 'types/users'

type ChattersContextProps = {
  children: React.ReactNode
}

type InternalChattersType = { [key in string]: ChatterType }

type ChattersStoreType = [
  {
    chatters: InternalChattersType
  },
  {
    save: (chatterData: ChatterType) => void
    saveMany: (chattersData: ChatterType[]) => void
  },
]

export const StoreContext = createContext<ChattersStoreType>([
  { chatters: {} },
  { save: () => {}, saveMany: () => {} },
])

export const ChattersContext = ({ children }: ChattersContextProps) => {
  const [chatters, setChatters] = useState<InternalChattersType>({})
  const [, { actionSubscribe }] = useApplication()

  const save = (chatterData: ChatterType) => {
    chatters[chatterData.id] = chatterData
    setChatters({ ...chatters })
  }

  const saveMany = (items: ChatterType[]) => {
    items.forEach(chatterData => {
      chatters[chatterData.id] = chatterData
    })

    setChatters({ ...chatters })
  }

  useEffect(() => {
    const updateUserStatus = (newStatus: UserAppStatusType) => (data: any) => {
      const chatterToUpdate = chatters[data.resource.user.chatterId]

      if (chatterToUpdate) {
        chatterToUpdate.userAppStatus = newStatus
        save(chatterToUpdate)
      }
    }

    const unsubscribeConnected = actionSubscribe(
      APPLICATION_EVENTS.USER_CONNECTED,
      updateUserStatus(USER_STATUS.CONNECTED),
    )
    const unsubscribeDisconnected = actionSubscribe(
      APPLICATION_EVENTS.USER_DISCONNECTED,
      updateUserStatus(USER_STATUS.DISCONNECTED),
    )
    return () => {
      unsubscribeConnected()
      unsubscribeDisconnected()
    }
  }, [chatters, save, saveMany])

  return (
    <StoreContext.Provider value={[{ chatters }, { save, saveMany }]}>
      {children}
    </StoreContext.Provider>
  )
}
const useChattersStore = () => useContext<ChattersStoreType>(StoreContext)

export default useChattersStore
