const DEFAULT_DURATION = 500
const cache = {}

const setDestroyTimeout = (key, duration) =>
  setTimeout(() => {
    delete cache[key]
  }, duration + 1)

export const set = (key, value, duration = DEFAULT_DURATION) => {
  cache[key] = {
    value,
    duration,
    storedTime: new Date(),
    destroyTimeout: setDestroyTimeout(key, duration),
  }
}

export const get = key => {
  const item = cache[key]
  if (!item) {
    return null
  }
  clearTimeout(cache[key].destroyTimeout)
  cache[key].destroyTimeout = setDestroyTimeout(key, cache[key].duration)

  console.log(`Cache match for: ${key}`)
  return item.value
}
