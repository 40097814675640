import { genericGet, genericParseResponse } from 'store/utils'
import {
  createCustomer as createCustomerApi,
  updateCustomer as updateCustomerApi,
  bulkImport as bulkImportApi,
} from './api'

const resourceBasePath = '/v1/customers?'

const useCustomers = () => {
  const getCustomers = (filters, size, offset) => {
    return genericGet(resourceBasePath, {
      filter: filters,
      page: { size, after: offset - 1 },
    }).then(genericParseResponse)
  }

  const createCustomer = data => {
    return createCustomerApi({
      ...data,
      source: 'MANUAL',
    })
  }

  const updateCustomer = data => {
    return updateCustomerApi({
      ...data,
      source: 'MANUAL',
    })
  }

  const bulkImport = (data, workspaceId) => {
    return bulkImportApi(data, workspaceId)
  }

  return [
    {
      getCustomers,
      createCustomer,
      updateCustomer,
      bulkImport,
    },
  ]
}

export default useCustomers
