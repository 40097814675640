import React from 'react'
import ReactDOM from 'react-dom'

import { HashRouter } from 'react-router-dom'
import { UserContext } from 'store/users/hook'
import { LayoutContext } from 'store/layout/hook'
import { ConfigProvider } from 'components/yoda/ConfigProvider'
import ErrorBoundaries from 'components/ErrorBoundaries/ErrorBoundaries'
import { BugsnagContext } from './store/bugsnag/hook'
import Router from './router'
import * as serviceWorker from './serviceWorker'

import packageJson from '../package.json'

console.log('%c Build version:', 'background: #222; color: #bada55', packageJson.version)

ReactDOM.render(
  <HashRouter>
    <BugsnagContext>
      <ErrorBoundaries>
        <UserContext>
          <ConfigProvider>
            <LayoutContext>
              <Router />
            </LayoutContext>
          </ConfigProvider>
        </UserContext>
      </ErrorBoundaries>
    </BugsnagContext>
  </HashRouter>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
