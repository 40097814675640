import qs from 'qs'

import { getToken, ApiErrorHandler } from '../utils'

const HOST = process.env.REACT_APP_BACKEND_URL

// eslint-disable-next-line
export function getExport(filter, serializer, export_format = 'csv') {
  const serializerFilters = qs.stringify(
    // eslint-disable-next-line
    { ...filter, serializer, export_format },
    { arrayFormat: 'brackets' },
  )

  return fetch(`${HOST}/v1/chats/export?${serializerFilters}`, {
    method: 'GET',
    headers: {
      authorization: getToken(),
    },
  }).then(ApiErrorHandler)
}

export const getMediaBlob = (chatId, mediaId) => {
  const serializerFilters = qs.stringify({ media_id: mediaId }, { arrayFormat: 'brackets' })
  return fetch(`${HOST}/v1/chats/${chatId}/media?${serializerFilters}`, {
    method: 'GET',
    headers: {
      authorization: getToken(),
    },
  }).then(response => response.blob())
}

export function updateChatTags(id, tagIds) {
  return fetch(`${HOST}/v1/chats/${id}/tag`, {
    method: 'POST',
    headers: {
      authorization: getToken(),
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      data: {
        attributes: {
          tag_ids: tagIds,
        },
      },
    }),
  }).then(ApiErrorHandler)
}
