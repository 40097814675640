import React, { Fragment, useEffect } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Loader from 'components/layout/Loader'
import { useIntl } from 'react-intl'
import useUser from 'store/users/hook'
import { useGoogleAnalytics } from 'store/googleAnalytics/hook'

import { getLayouts, getLayoutSection } from './utils'

const Layout = ({ children }) => {
  const location = useLocation()
  const { gevent, USER_EVENTS } = useGoogleAnalytics()
  const [{ loading, user }] = useUser()
  const intl = useIntl()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  useEffect(() => {
    gevent(USER_EVENTS.PAGE_VIEW, {
      page_path: location.pathname + location.search + location.hash,
    })
  }, [location.pathname])

  // Replace the label of loading component
  const el = document.querySelector('#nprogress .spinner')
  if (el) {
    const loadingMessageEl = document.createElement('span')
    loadingMessageEl.innerHTML = `${intl.formatMessage({ id: 'loading' })} ...`
    if (el.childNodes.length > 1) {
      el.replaceChild(loadingMessageEl, el.childNodes[1])
    } else {
      el.appendChild(loadingMessageEl)
    }
  }

  // Layout Rendering
  const Layouts = getLayouts(user && user.designId)
  const layoutSection = getLayoutSection(location.pathname)

  const Container = Layouts[layoutSection]
  const isUserAuthorized = !!(user && user.email)
  const isUserLoading = loading
  const isAuthLayout = layoutSection === 'auth'

  const BootstrappedLayout = () => {
    // show loader when user in check authorization process, not authorized yet and not on login pages
    if (isUserLoading && !isUserAuthorized && !isAuthLayout) {
      return <Loader />
    }
    // redirect to login page if current is not login page and user not authorized
    if (!isAuthLayout && !isUserAuthorized) {
      return (
        <Redirect
          to={`/system/login?redirect=${encodeURIComponent(
            window.location.hash.replace(/#\//g, ''),
          )}`}
        />
      )
    }
    // in other case render previously set layout
    return <Container>{children}</Container>
  }

  return (
    <Fragment>
      <Helmet titleTemplate="ChatCenter | %s" title="ChatCenter" />
      {BootstrappedLayout()}
    </Fragment>
  )
}

export default Layout
