import { formatBackendResponse } from 'store/utils'

import { EventType, ThreadType } from 'types/thread'
import { ChatDetailType, ChatListType } from 'types/chat'
import { UserType } from 'types/users'

// @ts-ignore
export const parseResponse = response => {
  const resp = {
    data: formatBackendResponse(response.data),
  }
  if (response.meta) {
    // @ts-ignore
    resp.meta = {
      totalCount: response.meta.page.total_count,
      ...response.links,
    }
  }
  return resp
}

export const getOmniCenterChatOrder = (chat: ChatDetailType, user: UserType) => {
  if (!chat.assigneeChatter) {
    return 0
  }
  if (chat.assigneeChatter.id === user.chatterId) {
    return 1
  }
  return 2
}

export const getOmniCenterDialogExtraProps = (chat: ChatDetailType, user: UserType) => {
  return {
    order: getOmniCenterChatOrder(chat, user),
  }
}

/**
 * Add required fields for a MELI chat,
 * or internal fields using on the Frontend side
 * @param {object} chat
 */
export const getMeliDialogExtraProps = (chat: ChatDetailType) => {
  const now = new Date()

  // process the message to identify where the tag 'Unread Message' should be placed
  let wasUnreadTagPlaced: boolean = false
  const threadsWithUnreadMessageProp = chat.threads.map((thread: ThreadType) => {
    return {
      ...thread,
      events: thread.events.map((event: EventType) => {
        const showUnreadMessage = !wasUnreadTagPlaced && chat.eventsSeenUpTo < event.timestamp
        if (showUnreadMessage) {
          wasUnreadTagPlaced = true
        }
        return {
          ...event,
          showUnreadMessage,
        }
      }),
    }
  })
  return {
    absoluteDateUntilReservationExpires: new Date(
      now.setSeconds(now.getSeconds() + chat.secondsUntilReservationExpires),
    ),
    messageTemplate: chat.appendSignature,
    threads: threadsWithUnreadMessageProp,
  }
}

export const consolidateChatList = (arr1: ChatListType[], arr2: ChatListType[]) => {
  // Create a Set to track unique chat IDs
  const uniqueIds = new Set()
  // Create an array to store the result
  const consolidatedChats: ChatListType[] = []

  // Iterate over arr1 and add unique chats to the result
  arr1.forEach(chat => {
    if (!uniqueIds.has(chat.id)) {
      uniqueIds.add(chat.id)
      consolidatedChats.push(chat)
    }
  })

  // Iterate over arr2 and add unique chats to the result, preserving their order
  arr2.forEach(chat => {
    if (!uniqueIds.has(chat.id)) {
      uniqueIds.add(chat.id)
      consolidatedChats.push(chat)
    } else {
      // Update the value from arr1 with the value from arr2 when there's a duplicate id
      const index = consolidatedChats.findIndex(item => item.id === chat.id)
      consolidatedChats[index] = chat
    }
  })

  return consolidatedChats
}
