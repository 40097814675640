import { notification } from 'antd'

export const NOTIFICATION_TYPE: {
  [key: string]: 'new_chat' | 'new_message' | 'update_chat' | 'close_chat'
} = {
  NEW_CHAT: 'new_chat',
  NEW_MESSAGE: 'new_message',
  UPDATE_CHAT: 'update_chat',
  CLOSE_CHAT: 'close_chat',
} as const

type NotificationTypeKeys = keyof typeof NOTIFICATION_TYPE

type NotificationsAudiosType = {
  // eslint-disable-next-line
  [key in NotificationTypeKeys]: HTMLAudioElement | null
}

const notificationsAudios: NotificationsAudiosType = {
  [NOTIFICATION_TYPE.NEW_CHAT]: new Audio('/resources/sounds/new-chat-notification.mp3'),
  [NOTIFICATION_TYPE.NEW_MESSAGE]: new Audio('/resources/sounds/notification.mp3'),
  [NOTIFICATION_TYPE.UPDATE_CHAT]: new Audio('/resources/sounds/notification.mp3'),
  [NOTIFICATION_TYPE.CLOSE_CHAT]: null,
}
/**
 * Method to show a Desktop notification if the user has granted access
 * and if he is not seeing the application
 * @deprecated use the NotificationManager instead
 */
function notifyMe(
  {
    title,
    description,
  }: {
    title?: string
    description?: string
  } = {},
  { notificationType = NOTIFICATION_TYPE.UPDATE_CHAT } = {},
) {
  const notificationAudio = notificationsAudios[notificationType]
  if (notificationAudio) {
    notificationAudio.play().catch(error => {
      console.log('Chrome cannot play sound without user interaction first', error)
    })
  }

  if (!Notification) {
    return
  }

  if (!title && !description) {
    return
  }

  if (!window.document.hidden) {
    notification.info({
      message: title,
      description,
    })
    return
  }

  if (Notification.permission !== 'granted') Notification.requestPermission()
  else {
    const notificationInstance = new window.Notification(title || '', {
      icon: 'resources/images/chat-center-logo.png',
      body: description,
    })
    notificationInstance.onclick = () => {
      window.focus()
    }
  }
}

export default notifyMe
