/**
 * Return true if the message was an automatic message
 */

import { APPLICATION_EVENTS } from 'enums/notifications'
import { ContextType, ApplicationEventType } from 'types/notifications'
import { chatAssignedDataType, chatThreadEventCreatedDataType } from '../types'

export function isAutomaticReplyCheck(action: ApplicationEventType, event: any) {
  if (action === APPLICATION_EVENTS.CHAT_THREAD_EVENT_CREATED && event.chatThreadEvent) {
    const threadCreateEvent: chatThreadEventCreatedDataType = event
    return (
      event.chatThreadEvent.authorType === 'seller' && !threadCreateEvent.chatThreadEvent.chatterId
    )
  }
  return false
}

/**
 * Return true if the message is generated by other user (excluding the current user)
 */
// eslint-disable-next-line
export function isMessageFromOtherChaterCheck(
  action: ApplicationEventType,
  event: any,
  context: ContextType,
) {
  const { user } = context
  if (action === APPLICATION_EVENTS.CHAT_THREAD_EVENT_CREATED && event.chatThreadEvent) {
    const threadCreateEvent: chatThreadEventCreatedDataType = event
    return `${threadCreateEvent.chatThreadEvent.chatterId}` !== user.chatterId
  }
  return false
}

/**
 * Return true if the event comes from a chat that has not assignee (and assignee group)
 */
export function isMessageFromAnUnnasignedChat(
  action: ApplicationEventType,
  event: any,
  context: ContextType,
) {
  if (action === APPLICATION_EVENTS.CHAT_THREAD_EVENT_CREATED) {
    const threadCreateEvent: chatThreadEventCreatedDataType = event
    return !threadCreateEvent.chat.assigneeChatter && !threadCreateEvent.chat.assigneeChatterGroup
  }
  return false
}
/**
 * Return true if the user is assigned to the chat
 * TODO check also if the user belows to the assignation group
 */
export function isChatAssignedToChatter(
  action: ApplicationEventType,
  event: any,
  context: ContextType,
) {
  const { user } = context
  if (action === APPLICATION_EVENTS.CHAT_THREAD_EVENT_CREATED) {
    const threadCreateEvent: chatThreadEventCreatedDataType = event
    return (
      threadCreateEvent.chat.assigneeChatter &&
      user.chatterId === `${threadCreateEvent.chat.assigneeChatter.id}`
    )
  }
  return false
}

/**
 * Return true if the assignator is the current user
 */
export function isAssignorCurrentChatterCheck(
  action: ApplicationEventType,
  event: any,
  context: ContextType,
) {
  const { user } = context
  if (action === APPLICATION_EVENTS.CHAT_ASSIGNED) {
    const chatAssignedEvent: chatAssignedDataType = event
    return user.chatterId === chatAssignedEvent.chatAssignor?.id
  }
  return false
}
/**
 * Return true if the assignee is the current user
 */
export function isAssigneeTheCurrentChatter(
  action: ApplicationEventType,
  event: any,
  context: ContextType,
) {
  const { user } = context
  if (action === APPLICATION_EVENTS.CHAT_ASSIGNED) {
    const chatAssignedEvent: chatAssignedDataType = event
    return user.chatterId === chatAssignedEvent.chat.assigneeChatter?.id
  }
  return false
}

/**
 * Return true if the chat is unnasigned
 */
export function isChatUnnasigned(action: ApplicationEventType, event: any, context: ContextType) {
  return action === APPLICATION_EVENTS.CHAT_UNASSIGNED
}

/**
 * Return true if the message was created by the current user
 */
export function isMessageSentByCurrentChatter(
  action: ApplicationEventType,
  event: any,
  context: ContextType,
) {
  const { user } = context
  if (action === APPLICATION_EVENTS.CHAT_THREAD_EVENT_CREATED) {
    const threadCreateEvent: chatThreadEventCreatedDataType = event
    return `${threadCreateEvent.chatThreadEvent.chatterId}` === user.chatterId
  }
  return false
}
