import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import React, { createContext, useContext } from 'react'
import packageJson from '../../../package.json'

const apiKey = process.env.REACT_APP_BUGSNAG_API_KEY || 'NO KEY DEFINED'

const errorsToDiscard = ['ResizeObserver']

const onError = event => {
  try {
    // check if the error is a flat string or an object
    const errorMessage =
      typeof event.originalError === 'string' ? event.originalError : event.originalError?.message

    // discard error if the message is in the skip error list
    if (
      errorsToDiscard.find(errorMessageToDiscard => {
        return errorMessage.indexOf(errorMessageToDiscard) > -1
      })
    ) {
      return false
    }

    // add current user information
    const userEmail = JSON.parse(localStorage.getItem('user')).email
    event.setUser(null, userEmail)

    // add metadata from the api
    if (event.originalError.data) {
      const apiError = event.originalError

      event.addMetadata('api_error_data', {
        requestId: apiError.requestId,
        data: apiError.data,
        message: apiError.message,
        statusCode: apiError.statusCode,
        url: apiError.url,
      })
    }
  } catch {
    // do nothing
  }

  return true
}

Bugsnag.start({
  apiKey,
  plugins: [new BugsnagPluginReact()],
  onError,
  appVersion: packageJson.version,
})

export const StoreContext = createContext({})

export const BugsnagContext = ({ children }) => {
  return <StoreContext.Provider value={[]}>{children}</StoreContext.Provider>
}
const useBugsnag = () => useContext(StoreContext)

export default useBugsnag
