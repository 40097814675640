import qs from 'qs'
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import useLocalStorage from 'utils/useLocalStorage'
import useQuery from 'utils/useQuery'

// the status filter is required, so if it is not present in the query-strings, add it manually
const requiredInitialFilters = {
  status: ['OPEN'],
}

// there are three levels of filters:
// queryString => localStorage => currentFilters (in memory)

function consolidateFilters(filters) {
  const finalFilters = {}

  // include the required filters
  Object.assign(finalFilters, requiredInitialFilters, { ...filters })

  return finalFilters
}

export const useOmnicenterFilters = () => {
  const [localStorageFilters, setLocalStorageFilters] = useLocalStorage(
    'omnicenter_filters',
    requiredInitialFilters,
  )
  const [query, search] = useQuery()

  // if the query string is defined, read the initial filters from there, if not, use the local storage
  const currentFilterSource = Object.keys(query).length ? query : localStorageFilters

  const [filters, setFiltersInternal] = useState(consolidateFilters(currentFilterSource))
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    // when the querystring change the filters should be updated
    if (location.pathname.match(/^\/chats\/?/g)) {
      const filtersFromQueryString = consolidateFilters(currentFilterSource)
      setFiltersInternal(filtersFromQueryString)
      history.replace({
        pathname: location.pathname.split('?')[0],
        search: `?${qs.stringify(filtersFromQueryString, {
          encode: false,
          arrayFormat: 'brackets',
        })}`,
      })
    }
  }, [location.pathname, search])

  const setFilters = newFilters => {
    setLocalStorageFilters(newFilters)
    // change the filters directly in the url
    history.push({
      pathname: location.pathname,
      search: `?${qs.stringify(newFilters, {
        encode: false,
        arrayFormat: 'brackets',
      })}`,
    })
  }

  return [
    {
      filters,
    },
    {
      setFilters,
    },
  ]
}
