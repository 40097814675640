import Bugsnag from '@bugsnag/js'
import React, { useMemo } from 'react'

const ErrorView = () => (
  <div>
    Oops... We always try to do our best, but sometimes it does not work. An unexpected error has
    happen.
  </div>
)

const ErrorBoundaries = ({ children }) => {
  const ErrorBoundary = useMemo(() => Bugsnag.getPlugin('react').createErrorBoundary(React), [])

  return <ErrorBoundary FallbackComponent={ErrorView}>{children}</ErrorBoundary>
}

export default ErrorBoundaries
