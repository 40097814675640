import { ContextType } from 'types/notifications'
import { chatThreadTaggedEventDataType } from '../types'

export const omniChatThreadTaggedHandler = (
  context: ContextType,
  data: chatThreadTaggedEventDataType,
) => {
  const { setChats, chats, chatDetail, setChatDetail } = context

  const chatListItem = chats.find(chat => chat.id === `${data.chat.id}`)
  if (chatListItem) {
    chatListItem.tags = data.chat.tags

    setChats([...chats])
  }

  if (chatDetail?.id === `${data.chat.id}`) {
    setChatDetail({
      ...chatDetail,
      tags: data.chat.tags,
    })
  }
}
