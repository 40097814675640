import notifyMe from '../../utils/notification'

// Request Notification permissions
document.addEventListener('DOMContentLoaded', () => {
  if (window.Notification && window.Notification.permission !== 'granted')
    window.Notification.requestPermission()
})

export const advaiceNewMessage = (title: string, description: string) => {
  const chatCenterMenuEl = document.querySelector('.chat-center-menu-option')
  if (chatCenterMenuEl && window.location.hash.indexOf('chat-center') === -1) {
    chatCenterMenuEl.classList.add('new-notification')
  }
  notifyMe({ title, description })
}

export const removeAdvaiceNewMessage = () => {
  const chatCenterMenuEl = document.querySelector('.chat-center-menu-option')
  if (chatCenterMenuEl) {
    chatCenterMenuEl.classList.remove('new-notification')
  }
}

export const advaiceBdcPendingConfirmation = (penddings: string) => {
  const showPendingsQuestions = () => {
    const bdcMenuEl = document.querySelector('.chat-bdc-menu-option')
    if (bdcMenuEl) {
      bdcMenuEl.classList.add('new-notification-message')
      bdcMenuEl.setAttribute('data-after', penddings)
    } else {
      // try until element appears
      setTimeout(showPendingsQuestions, 1000)
    }
  }

  showPendingsQuestions()
}
