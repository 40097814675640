import React from 'react'
import { ConfigProvider as ConfigProviderAntd } from 'antd'

// Load yoda and antd css
import 'antd/dist/reset.css'
import styles from '../../../assets/styles/yoda/yoda.module.scss'

export const ConfigProvider = ({ children }) => {
  return (
    <ConfigProviderAntd
      theme={{
        token: {
          colorPrimary: styles['yoda-color-green-2'],
          colorBgLayout: styles['yoda-color-gray-16'],
          fontFamily: 'Roboto, sans-serif',
          fontSize: 14,
          borderRadius: 6,
          yoda: {
            colors: Object.keys(styles)
              .filter(key => key.includes('yoda-color'))
              .reduce(
                (acc, key) => ({ ...acc, [key.replace(/yoda-color-/g, '')]: styles[key] }),
                {},
              ),
          },
        },
      }}
    >
      {children}
    </ConfigProviderAntd>
  )
}
