import React from 'react'

type AntdFormFieldValidationError = {
  validateStatus: '' | 'success' | 'error' | 'warning' | 'validating' | undefined
  help: any
}

const getErrorForLevel = (
  [path, ...pathTail]: string[],
  errorData: any,
): AntdFormFieldValidationError | null => {
  const currentErrorData = (errorData || {})[path]
  if (currentErrorData) {
    if (pathTail.length) {
      return getErrorForLevel(pathTail, errorData[path])
    }
    return {
      validateStatus: 'error',
      help:
        typeof currentErrorData === 'object'
          ? Object.keys(currentErrorData).map(key => {
              return <React.Fragment key={key}>{currentErrorData[key]}</React.Fragment>
            })
          : currentErrorData,
    }
  }

  return null
}

export default getErrorForLevel
