import React from 'react'
import {
  ContextType,
  NotificationChannelType,
  NotificationEventType,
  NotificationManagerType,
  NotificationType,
  ApplicationEventType,
} from 'types/notifications'
import {
  NOTIFICATION_CHANNEL,
  NOTIFICATION_TYPE,
  APPLICATION_EVENTS,
} from '../../../enums/notifications'
import NewChatNotification from '../NewChatNotification'
import {
  chatAssignedDataType,
  chatOpenDataType,
  chatThreadEventCreatedDataType,
  chatThreadOpenDataType,
} from './types'
import { notify } from './utils/notify'
import {
  isAssigneeTheCurrentChatter,
  isAssignorCurrentChatterCheck,
  isAutomaticReplyCheck,
  isChatAssignedToChatter,
  isChatUnnasigned,
  isMessageFromAnUnnasignedChat,
  isMessageSentByCurrentChatter,
} from './utils/validators'

export function NotificationManager(this: NotificationManagerType) {
  this.shouldNotify = (
    action: ApplicationEventType,
    event: NotificationEventType,
    context: ContextType,
  ) => {
    // TODO the list of functions below should be enable/disable by user preferences

    // Additive conditions: Increase the chances to create an alert (A || B || C)
    const applyForAditiveConditions = !![
      // thread event checks
      // isMessageFromOtherChaterCheck(action, event, context), // to reduce spaming of notification this check will be disabled
      isMessageFromAnUnnasignedChat(action, event, context),
      isChatAssignedToChatter(action, event, context),
      // asignation events check
      isAssignorCurrentChatterCheck(action, event, context),
      isAssigneeTheCurrentChatter(action, event, context),
      isChatUnnasigned(action, event, context),
    ].reduce((acc, itm) => {
      return acc || itm
    }, false)

    // Restrictive conditions: reduce the changes to create an alert (A && B && C)
    const intersection = !![
      !isAutomaticReplyCheck(action, event),
      !isMessageSentByCurrentChatter(action, event, context),
    ].reduce((acc, itm) => {
      return acc && itm
    }, applyForAditiveConditions)

    return intersection
  }

  this.getNotificationType = (action: ApplicationEventType): NotificationType => {
    if (action === APPLICATION_EVENTS.CHAT_THREAD_EVENT_CREATED) {
      return NOTIFICATION_TYPE.NEW_MESSAGE
    }
    if (action === APPLICATION_EVENTS.CHAT_OPEN) {
      return NOTIFICATION_TYPE.NEW_CHAT
    }
    if (action === APPLICATION_EVENTS.CHAT_CLOSED) {
      return NOTIFICATION_TYPE.CLOSE_CHAT
    }

    return NOTIFICATION_TYPE.GENERIC
  }

  this.getChannelType = (): NotificationChannelType => {
    if (window.document.hidden) {
      return NOTIFICATION_CHANNEL.DESKTOP
    }
    return NOTIFICATION_CHANNEL.WEB
  }

  this.getNotificationTitle = (
    action: ApplicationEventType,
    event: any,
    context: ContextType,
    notificationChannel: NotificationChannelType,
  ): string | null => {
    const { intl, user, chatDetail } = context
    if (action === APPLICATION_EVENTS.CHAT_THREAD_EVENT_CREATED) {
      const threadCreateEvent: chatThreadEventCreatedDataType = event

      // if the user is on the current chat, then it's not needed to show a visual notification (only the sound)
      if (
        notificationChannel === NOTIFICATION_CHANNEL.WEB &&
        chatDetail?.id === threadCreateEvent.chat.id
      ) {
        return null
      }
      return threadCreateEvent.chatThreadEvent.text
        ? intl.formatMessage(
            { id: 'newIncomingMessage' },
            { text: threadCreateEvent.chatThreadEvent.text },
          )
        : intl.formatMessage({ id: 'newIncomingMessage2' })
    }
    if (action === APPLICATION_EVENTS.CHAT_ASSIGNED) {
      const chatAssignedEvent: chatAssignedDataType = event
      const isAssignorCurrentChatter = user.chatterId === chatAssignedEvent.chatAssignor?.id
      const isAssigneeCurrentChatter = user.chatterId === chatAssignedEvent.chat.assigneeChatter?.id
      const isAssigneeUnnasigned = !chatAssignedEvent.chat.assigneeChatter

      const chatterSource = isAssignorCurrentChatter ? 'currentChatter' : 'otherChatter'
      // eslint-disable-next-line
      const chatterTarget = isAssigneeCurrentChatter
        ? 'currentChatter'
        : isAssigneeUnnasigned
        ? 'unassigned'
        : 'otherChatter'

      const translationKeysMapping = {
        currentChatter: {
          currentChatter: 'chatAssignedYourself',
          otherChatter: 'chatAssignedFromYouToAnotherNotification',
          unassigned: 'chatUnassigned',
        },
        otherChatter: {
          currentChatter: 'chatAssignedToYouFromAnotherNotification',
          otherChatter: undefined,
          unassigned: undefined,
        },
      }

      const translationKey = translationKeysMapping[chatterSource][chatterTarget]
      if (translationKey) {
        return intl.formatMessage(
          { id: translationKey },
          {
            chatId: chatAssignedEvent.chat.id,
            userName: chatAssignedEvent.chat?.assigneeChatter?.displayName,
          },
        )
      }
    }
    if (action === APPLICATION_EVENTS.CHAT_UNASSIGNED) {
      return intl.formatMessage({ id: 'chatUnassigned' })
    }

    return 'New notification'
  }

  this.getNotificationContent = (
    action: ApplicationEventType,
    event: any,
    context: ContextType,
    notificationChannel: NotificationChannelType,
  ) => {
    const { intl } = context
    if (notificationChannel === 'desktop') {
      if (action === APPLICATION_EVENTS.CHAT_OPEN) {
        const chatThreadOpenEvent: chatThreadOpenDataType = event
        return intl.formatMessage(
          { id: 'visitChatwithId' },
          { chatId: chatThreadOpenEvent.chat.id },
        )
      }
      if (action === APPLICATION_EVENTS.CHAT_THREAD_EVENT_CREATED) {
        const threadCreateEvent: chatThreadEventCreatedDataType = event
        return intl.formatMessage({ id: 'visitChatwithId' }, { chatId: threadCreateEvent.chat.id })
      }
    }
    if (notificationChannel === 'web') {
      if (action === APPLICATION_EVENTS.CHAT_THREAD_EVENT_CREATED) {
        const threadCreateEvent: chatThreadEventCreatedDataType = event
        return (
          <NewChatNotification
            title={intl.formatMessage({ id: 'visitChat' })}
            chatId={threadCreateEvent.chat.id}
          />
        )
      }
      if (action === APPLICATION_EVENTS.CHAT_OPEN) {
        const chatThreadOpenEvent: chatThreadOpenDataType = event
        return (
          <NewChatNotification
            title={intl.formatMessage({ id: 'visitChat' })}
            chatId={chatThreadOpenEvent.chat.id}
          />
        )
      }
    }

    return undefined
  }

  this.getNotificationClickAction = (
    context: ContextType,
    event: any,
    action: ApplicationEventType,
    notificationChannel: NotificationChannelType,
  ) => {
    if (notificationChannel === 'desktop') {
      if (action === APPLICATION_EVENTS.CHAT_OPEN) {
        const chatOpenEvent: chatOpenDataType = event
        return `${window.location.origin}/#/chats/${chatOpenEvent.chat.id}`
      }
      if (action === APPLICATION_EVENTS.CHAT_THREAD_OPEN) {
        const chatThreadOpenEvent: chatThreadOpenDataType = event
        return `${window.location.origin}/#/chats/${chatThreadOpenEvent.chat.id}`
      }
      if (action === APPLICATION_EVENTS.CHAT_THREAD_EVENT_CREATED) {
        const threadCreateEvent: chatThreadEventCreatedDataType = event
        return `${window.location.origin}/#/chats/${threadCreateEvent.chat.id}`
      }
      return undefined
    }
    return undefined
  }

  this.execute = (
    action: ApplicationEventType,
    event: NotificationEventType,
    context: ContextType,
  ) => {
    if (!this.shouldNotify(action, event, context)) {
      return
    }

    const notificationChannel = this.getChannelType()
    const notificationType = this.getNotificationType(action)

    const notificationTitle = this.getNotificationTitle(action, event, context, notificationChannel)
    const notificationContent = this.getNotificationContent(
      action,
      event,
      context,
      notificationChannel,
    )
    const notificationClickAction = this.getNotificationClickAction(
      context,
      event,
      action,
      notificationChannel,
    )

    notify(
      notificationChannel,
      notificationType,
      notificationTitle,
      notificationContent,
      notificationClickAction,
    )
  }

  return this
}
