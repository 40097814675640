const CACHE_EXPIRATION_TIME = 300000 // Set expiration time to 5 minutes (300,000 milliseconds)

export function ChatStore(getEntry) {
  this.cacheMap = new Map() // Internal cache to store entries and their expiration timestamps

  this.get = async id => {
    // Check if the entry is present in the cache
    if (this.cacheMap.has(id)) {
      const { entry, expirationTime } = this.cacheMap.get(id)

      // Check if the cached entry has expired
      if (Date.now() > expirationTime) {
        this.invalidate(id) // Entry has expired, remove it from the cache
      } else {
        return entry // Return the valid cached entry
      }
    }

    // If not present in the cache or expired, fetch the entry
    const entry = await getEntry(id)
    const expirationTime = Date.now() + CACHE_EXPIRATION_TIME

    // Add the entry and expiration time to the cache
    this.cacheMap.set(id, { entry, expirationTime })

    return entry
  }

  this.invalidate = id => {
    // Remove the specified entry and its expiration time from the cache
    this.cacheMap.delete(id)
  }

  this.set = (id, entry) => {
    // Update the entry and refresh the expiration time if it exists in the cache
    if (this.cacheMap.has(id)) {
      const newExpirationTime = Date.now() + 300000 // Set new expiration time to 5 minutes (300,000 milliseconds)

      // Update the entry and expiration time in the cache
      this.cacheMap.set(id, { entry, expirationTime: newExpirationTime })
    } else {
      const expirationTime = Date.now() + 300000 // Set expiration time to 5 minutes (300,000 milliseconds)
      // Add the entry and expiration time to the cache
      this.cacheMap.set(id, { entry, expirationTime })
    }
  }

  return this
}
