import React, { useState, useEffect, useRef, createContext, useContext } from 'react'
// eslint-disable-next-line
import { unstable_batchedUpdates } from 'react-dom'
import { get } from 'lodash'
import { notification } from 'antd'
import { useIntl } from 'react-intl'
import {
  formatBackendResponse,
  genericGet,
  genericParseResponseOneItem,
  useForceUpdate,
} from 'store/utils'
import useLocalStorage from 'utils/useLocalStorage'
import useUser from 'store/users/hook'
import { CHAT_CHANNELS } from 'enums/channels'
import { APPLICATION_EVENTS } from 'enums/notifications'
import useCustomers from 'store/customers/hook'
import useApplication from 'store/application/hook'
import useChats from './chats'
import {
  answerChat as answerChatApi,
  assignChat as assignChatApi,
  unassignChat as unassignChatApi,
  closeChat as closeChatApi,
  emptyChatThreadEvents as emptyChatThreadEventsApi,
  openChat as openChatApi,
  takeOver as takeOverApi,
  markEventsAsSeen as markEventsAsSeenApi,
} from './api'

import { updateChatTags as updateChatTagsApi } from '../dialog/api'

import { getMeliDialogExtraProps, consolidateChatList } from './utils'

import { omniChatThreadOpenHandler, omniNewChatThreadEventHandler } from './serverEvents'

import { ChatStore } from './store'
import { omniCenterChatAssignedEventHandler } from './serverEvents/handlers/chatAssigned'
import { omniCenterChatUnassignedEventHandler } from './serverEvents/handlers/chatUnassigned'
import { omniChatThreadTaggedHandler } from './serverEvents/handlers/threadTagged'
import { omniCenterChatThreadEventUpdatedHandler } from './serverEvents/handlers/threadEventUpdate'
import { omniCenterChatThreadEventCreatedHandler } from './serverEvents/handlers/threadEventCreated'
import { omniCenterChatThreadClosedHandler } from './serverEvents/handlers/threadEventClosed'
import { omniChatOpenEventHandler } from './serverEvents/handlers/chatOpen'
import { omniChatClosedEventHandler } from './serverEvents/handlers/chatClosed'
import { NotificationManager } from './serverEvents/notificationManager'
import { useChatThreads } from './chatThreadsHook'
import { useOmnicenterFilters } from './useOmnicenterFilters'

export const StoreContext = createContext({})

const OMNICENTER_ACTIONS_HANDLERS = {
  [APPLICATION_EVENTS.CHAT_ASSIGNED]: omniCenterChatAssignedEventHandler,
  [APPLICATION_EVENTS.CHAT_THREAD_CLOSED]: omniCenterChatThreadClosedHandler,
  [APPLICATION_EVENTS.CHAT_THREAD_EVENT_CREATED]: omniCenterChatThreadEventCreatedHandler,
  [APPLICATION_EVENTS.CHAT_THREAD_EVENT_UPDATED]: omniCenterChatThreadEventUpdatedHandler,
  [APPLICATION_EVENTS.CHAT_THREAD_OPEN]: omniChatThreadOpenHandler,
  [APPLICATION_EVENTS.CHAT_THREAD_TAGGED]: omniChatThreadTaggedHandler,
  [APPLICATION_EVENTS.CHAT_UNASSIGNED]: omniCenterChatUnassignedEventHandler,
  [APPLICATION_EVENTS.NEW_CHAT_THREAD_EVENT]: omniNewChatThreadEventHandler,
  [APPLICATION_EVENTS.CHAT_CLOSED]: omniChatClosedEventHandler,
  [APPLICATION_EVENTS.CHAT_OPEN]: omniChatOpenEventHandler,
}

const defaultFilters = {
  status: ['OPEN'],
}

const defaultSort = {
  column: 'last_interaction_at',
  order: 'descend',
}

const PAGE_SIZE = 10

const chatStore = new ChatStore(id =>
  genericGet(`/v1/chats/${id}`).then(resp => formatBackendResponse(resp.data)),
)

const notificationManager = new NotificationManager()

export const OmniCenterContext = ({ children }) => {
  const intl = useIntl()
  const [{ user }] = useUser()
  const [loading, setLoading] = useState()
  const [loadingChatList, setLoadingChatList] = useState()
  const [chats, setChats] = useState([])
  const [hasMore, setHasMore] = useState(true)
  const [initialFilters, setInitialFilters] = useLocalStorage('omnicenter_filters', defaultFilters)
  const [initialSort, setInitialSort] = useLocalStorage('omnicenter_sort', defaultSort)
  const [assignmentInProgress, setAssignmentInProgress] = useState(false)
  const [isInErrorState, setIsInErrorState] = useState(false)

  const [{ filters }, { setFilters }] = useOmnicenterFilters()

  const [{ sort }, { getData, setSort }] = useChats(filters, initialSort)
  const chatDetail = useRef()
  const forceUpdate = useForceUpdate()
  // chatId Id of the current selected chat
  const [chatId, setChatId] = useState()

  const [, { actionSubscribe }] = useApplication()

  const [{ threads }, { onNavigateToNextThread, onNavigateToPreviousThread }] = useChatThreads(
    chatDetail.current,
  )
  const [{ updateCustomer: updateCustomerHook }] = useCustomers()

  const setChatDetail = newChatDetailData => {
    chatDetail.current = newChatDetailData
    // newChatDetailData might be 'null'
    if (newChatDetailData) {
      // Update the new data in the internal cache
      chatStore.set(newChatDetailData.id, newChatDetailData)
    }

    forceUpdate()
  }

  // bind all events
  useEffect(() => {
    const unsubscribeFunctions = Object.entries(OMNICENTER_ACTIONS_HANDLERS).map(
      ([key, omnicenterActionHandler]) => {
        const wrappedOmnicenterActionHandler = data => {
          const context = {
            chats,
            user,
            intl,
            setChats,
            chatDetail: chatDetail.current,
            setChatDetail,
            chatStore,
            filters,
            sort,
            setAssignmentInProgress,
          }

          omnicenterActionHandler(context, data.resource)
          notificationManager.execute(data.action, data.resource, context)
        }
        return actionSubscribe(key, wrappedOmnicenterActionHandler)
      },
    )

    return () => {
      unsubscribeFunctions.map(unsubscribeFn => unsubscribeFn())
    }
  }, [chats, user, intl, chatDetail.current, chatStore, filters, sort])

  useEffect(() => {
    setLoadingChatList(true)
    setInitialFilters(filters)
    setInitialSort(sort)
    getData(PAGE_SIZE, 0)
      .then(resp => {
        unstable_batchedUpdates(() => {
          setChats(resp.data)
          setLoadingChatList(false)
          setHasMore(!!resp.meta.next)
        })
      })
      .catch(e => {
        setLoadingChatList(false)
        console.error(e)
        notification.error({
          message: get(e, 'data.message', intl.formatMessage({ id: 'genericError' })),
        })
      })
  }, [JSON.stringify(filters), sort])

  const takeOver = id => takeOverApi(id)

  const closeChat = id => closeChatApi(id)

  const emptyChatThreadEvents = id => emptyChatThreadEventsApi(id)

  const markEventsAsSeen = id => {
    if (!id) {
      return Promise.reject(new Error('Chat id was not defined'))
    }
    // update the local chats with the new date
    const newEventsSeenUpDate = Math.round(new Date().getTime() / 1000)

    unstable_batchedUpdates(() => {
      if (chatDetail.current?.id === `${id}`) {
        chatDetail.current.unreadMessagesCount = 0
        chatDetail.current.eventsSeenUpTo = newEventsSeenUpDate
        setChatDetail(chatDetail.current)
      }

      const chatListItem = chats.find(chat => chat.id === `${id}`)
      if (chatListItem) {
        chatListItem.unreadMessagesCount = 0
        chatListItem.eventsSeenUpTo = newEventsSeenUpDate
      }
      setChats([...chats])
    })

    return markEventsAsSeenApi(id)
  }

  /**
   *
   * @param {*} id
   * @param {*} assignId it could be a chatterId or chatterGroupId deppending on the assignedType param
   * @param {chatters|chatter_groups} assignedType
   * @returns
   */
  const assignChat = (id, assignId, assignedType) => {
    const data = {}

    setAssignmentInProgress(true)

    if (!assignId) {
      return unassignChatApi(id)
    }

    if (assignedType === 'chatters') {
      data.chatter_id = assignId
    } else {
      data.chatter_group_id = assignId
    }
    return assignChatApi(id, data)
  }

  /**
   * Method to answer chat via Chatcenter API (Livechat uses the LC socket)
   */
  const answerChat = ({ chatId: id, messageId, text }) => {
    if (
      ![
        CHAT_CHANNELS.MELI_PRE_SALE,
        CHAT_CHANNELS.MELI_AFTER_SALE,
        CHAT_CHANNELS.WHATSAPP,
      ].includes(chatDetail.current.channel)
    ) {
      throw new Error('Method supported only for MELI and WHATSAPP')
    }
    // agregar la respuesta del backend al chatDetail para que se muestre el mensaje instantaneamente
    return answerChatApi(id, messageId, text)
      .catch(err => {
        notification.error({
          message: get(err, 'data.message', err.message),
        })
      })
      .then(genericParseResponseOneItem)
      .then(({ data }) => {
        const thread = chatDetail.current.threads.find(t => t.id === `${data.chatThreadId}`)
        if (thread) {
          thread.events.push(data)
          setChatDetail(chatDetail.current)
        }
      })
  }

  const updateChatTags = tags => {
    const tagIds = tags.map(nt => nt.id)
    return updateChatTagsApi(chatId, tagIds)
  }

  const getChat = chatIdPrm => {
    setLoading(true)
    chatStore
      .get(chatIdPrm)
      .then(data => {
        const channel = get(data, 'channel')

        if (
          [
            CHAT_CHANNELS.MELI_PRE_SALE,
            CHAT_CHANNELS.MELI_AFTER_SALE,
            CHAT_CHANNELS.WHATSAPP,
            CHAT_CHANNELS.FB_MSN,
            CHAT_CHANNELS.WEB,
          ].includes(channel)
        ) {
          setChatDetail({
            ...data,
            ...getMeliDialogExtraProps(data),
          })
          setLoading(false)
        }
      })
      .catch(error => {
        setIsInErrorState(true)
        notification.error({
          message: get(error, 'data.message', error.message),
        })
        setLoading(false)
      })
  }

  const getNextPage = () => {
    if (loadingChatList || !hasMore || isInErrorState) return
    setLoadingChatList(true)
    getData(PAGE_SIZE, chats.length)
      .then(resp => {
        unstable_batchedUpdates(() => {
          // merge the new results with the previous one to avoid duplicates
          setChats(consolidateChatList(chats, resp.data))
          setLoadingChatList(false)
          setHasMore(PAGE_SIZE + chats.length < resp?.meta?.totalCount && resp.data.length !== 0)
        })
      })
      .catch(e => {
        setLoadingChatList(false)
        setIsInErrorState(true)
        notification.error({
          message: get(e, 'data.message', intl.formatMessage({ id: 'genericError' })),
        })
      })
  }

  const openChat = dialog => {
    return openChatApi(dialog)
      .then(() => {
        setLoading(false)
      })
      .catch(e => {
        setLoading(false)
        notification.error({
          message: get(e, 'data.message', intl.formatMessage({ id: 'genericError' })),
        })
      })
  }

  const updateCustomer = (data, id) => {
    return updateCustomerHook(data).then(() => {
      chatStore.invalidate(id)
    })
  }

  useEffect(() => {
    if (chatId) {
      getChat(chatId)
    } else {
      setChatDetail(null)
      forceUpdate()
    }
  }, [chatId])

  // Temporal, adding replacing the threads locally to simulate pagination
  const chatDetailReduced = chatDetail.current && {
    ...chatDetail.current,
    threads,
  }

  return (
    <StoreContext.Provider
      value={[
        {
          assignmentInProgress,
          chatDetail: chatDetailReduced,
          chats,
          filters,
          hasMore,
          loading,
          loadingChatList,
          permissions: get(chatDetail.current, 'permissions', {}),
          sort,
        },
        {
          answerChat,
          assignChat,
          closeChat,
          emptyChatThreadEvents,
          getChat,
          getNextPage,
          markEventsAsSeen,
          onNavigateToNextThread,
          onNavigateToPreviousThread,
          openChat,
          setChatId, // Set the id of the current chat
          setFilters,
          setSort,
          takeOver,
          updateChatTags,
          updateCustomer,
        },
      ]}
    >
      {children}
    </StoreContext.Provider>
  )
}

const useOmniCenter = () => useContext(StoreContext)

export default useOmniCenter
