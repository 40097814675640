import { ContextType } from 'types/notifications'
import { chatAssignedDataType } from '../types'
import { updateChatAssigneeHandler } from '../utils'

export const omniCenterChatAssignedEventHandler = (
  context: ContextType,
  data: chatAssignedDataType,
) => {
  updateChatAssigneeHandler({
    unassigned: false,
    chatter: data.chat.assigneeChatter,
    chatterGroup: data.chat.assigneeChatterGroup,
  })(context, data)
}
