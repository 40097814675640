import React from 'react'
import Loadable from 'react-loadable'
import Loader from 'components/layout/Loader'
import PublicLayout from './Public'
import AuthLayout from './Auth'

const LodableYodaAppLayout = Loadable({
  loader: () => import('./yoda/App'),
  loading: () => <Loader />,
})

export const getLayouts = () => {
  return {
    public: PublicLayout,
    auth: AuthLayout,
    app: LodableYodaAppLayout,
  }
}

export const getLayoutSection = pathname => {
  if (pathname === '/') {
    return 'public'
  }
  if (/^\/system(?=\/|$)/i.test(pathname)) {
    return 'auth'
  }
  return 'app'
}
