import { ApiErrorHandler, getToken } from 'store/utils'

const HOST = process.env.REACT_APP_BACKEND_URL

export const takeOver = chatId => {
  return fetch(`${HOST}/v1/chats/${chatId}/take_over`, {
    method: 'PUT',
    headers: {
      authorization: getToken(),
    },
  }).then(ApiErrorHandler)
}

export const closeChat = chatId => {
  return fetch(`${HOST}/v1/chats/${chatId}/close`, {
    method: 'POST',
    headers: {
      authorization: getToken(),
    },
  }).then(ApiErrorHandler)
}

export const emptyChatThreadEvents = chatId => {
  return fetch(`${HOST}/v1/chats/${chatId}/empty_events`, {
    method: 'PUT',
    headers: {
      authorization: getToken(),
    },
  }).then(ApiErrorHandler)
}

export const assignChat = (chatId, data) => {
  return fetch(`${HOST}/v1/chats/${chatId}/assign`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/vnd.api+json',
      authorization: getToken(),
    },
    body: JSON.stringify({
      data: {
        attributes: {
          ...data,
        },
      },
    }),
  }).then(ApiErrorHandler)
}

export const unassignChat = chatId => {
  return fetch(`${HOST}/v1/chats/${chatId}/unassign`, {
    method: 'PUT',
    headers: {
      authorization: getToken(),
    },
  }).then(ApiErrorHandler)
}

export function openChat(id) {
  return fetch(`${HOST}/v1/chats/${id}/open`, {
    method: 'PUT',
    headers: {
      authorization: getToken(),
    },
  }).then(ApiErrorHandler)
}

export function markEventsAsSeen(chatId) {
  return fetch(`${HOST}/v1/chats/${chatId}/mark_events_as_seen`, {
    method: 'PUT',
    headers: {
      authorization: getToken(),
    },
  }).then(ApiErrorHandler)
}

export function answerChat(chatId, messageId, text) {
  return fetch(`${HOST}/v1/chats/${chatId}/reply`, {
    method: 'POST',
    headers: {
      authorization: getToken(),
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      data: {
        attributes: {
          text,
          message_id: messageId,
        },
      },
    }),
  }).then(ApiErrorHandler)
}
