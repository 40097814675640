import { getToken, ApiErrorHandler } from '../utils'

const HOST = process.env.REACT_APP_BACKEND_URL

export async function login(email, password) {
  return fetch(`${HOST}/v1/sessions`, {
    method: 'POST',
    body: JSON.stringify({
      data: {
        type: 'sessions',
        attributes: {
          username: email,
          password,
        },
      },
    }),
    headers: {
      'content-type': 'application/vnd.api+json',
    },
  }).then(res => res.json())
}

export async function getCurrentUser(serializer) {
  let url = `${HOST}/v1/me`
  if (serializer) {
    url += `?serializer=${serializer}`
  }
  return fetch(url, {
    method: 'GET',
    headers: {
      'content-type': 'application/vnd.api+json',
      authorization: getToken(),
    },
  })
    .then(resp => {
      if (resp.status !== 200) {
        throw new Error(resp.status)
      }
      return resp
    })
    .then(res => res.json())
}

export async function askResetPassword(email) {
  return fetch(`${HOST}/v1/user/ask_password_reset`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      email,
    }),
  })
    .then(resp => {
      if (resp.status !== 201) {
        return Promise.reject(resp)
      }
      return resp
    })
    .then(res => res.json())
    .catch(resp => {
      return resp.json().then(data => Promise.reject(new Error(data.error.type)))
    })
}

export async function setNewPassword(newPassword, token) {
  return fetch(`${HOST}/v1/user/password`, {
    method: 'PUT',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      password: newPassword,
      password_confirmation: newPassword,
      password_reset_token: token,
    }),
  }).then(ApiErrorHandler)
}

export const getAccounts = id => {
  return fetch(`${HOST}/v1/accounts/${id}`, {
    method: 'GET',
    headers: {
      authorization: getToken(),
    },
  })
    .then(resp => {
      if (![200].includes(resp.status)) {
        throw new Error(resp.status)
      }
      return resp
    })
    .then(res => res.json())
}

export const revokeToken = () => {
  return fetch(`${HOST}/v1/sessions/revoke`, {
    method: 'DELETE',
    headers: {
      authorization: getToken(),
    },
  })
    .then(resp => {
      if (resp.status !== 200) {
        throw new Error(resp.status)
      }
      return resp
    })
    .then(res => res.json())
}
