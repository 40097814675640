import { useRef, useState } from 'react'
// eslint-disable-next-line
import { unstable_batchedUpdates } from 'react-dom'
import { useForceUpdate } from 'store/utils'
import { ChatDetailType } from 'types/chat'
import { ThreadType } from 'types/thread'

const getPreviousThread = (chat: ChatDetailType, threads: ThreadType[]): Promise<ThreadType> =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve(chat.threads[chat.threads.length - threads.length - 1])
    }, 1)
  })

export const useChatThreads = (chat: ChatDetailType | undefined) => {
  const threads = useRef<ThreadType[]>([])
  const [hasMore, setHasMore] = useState<boolean>()
  const loading = useRef<boolean>(false)
  const currentChatId = useRef<string>('')
  const forceUpdate = useForceUpdate()

  if (chat?.id !== currentChatId.current) {
    // reset the threads list
    threads.current.length = 0
    currentChatId.current = chat?.id || ''
    loading.current = false
  } else {
    // update the current visible threads
    threads.current = threads.current.map(t => {
      const updatedThread = chat.threads.find(chatThread => chatThread.id === t.id)
      if (updatedThread) {
        return updatedThread
      }
      return t
    })
  }

  const onNavigateToPreviousThread = async () => {
    if (loading.current) {
      // skip if it is loading
      return
    }
    if (threads.current.length === chat?.threads.length) {
      // If there are no more threads, just skip
      return
    }

    loading.current = true

    if (chat) {
      const newThread = await getPreviousThread(chat, threads.current)

      // discard the response if the current chat has changed
      if (currentChatId.current === chat.id) {
        unstable_batchedUpdates(() => {
          threads.current = [newThread, ...threads.current]
          if (threads.current.length === chat?.threads.length) {
            setHasMore(false)
          }

          loading.current = false
        })
      } else {
        // skipping change because different id
      }
    }

    forceUpdate()
  }

  const onNavigateToNextThread = () => {
    // this is not needed for now, because
    // we always start showing the last thread
  }

  return [
    {
      threads: threads.current,
      hasMore,
    },
    {
      onNavigateToPreviousThread,
      onNavigateToNextThread,
    },
  ]
}
