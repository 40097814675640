import { ContextType } from 'types/notifications'
import { chatUnassignedDataType } from '../types'
import { updateChatAssigneeHandler } from '../utils'

export const omniCenterChatUnassignedEventHandler = (
  context: ContextType,
  data: chatUnassignedDataType,
) => {
  updateChatAssigneeHandler({
    unassigned: true,
  })(context, data)
}
