import qs from 'qs'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

export default function useQuery() {
  const { search } = useLocation()
  return useMemo(() => {
    const searchWithoutQuestionMark = search.replace(/^\?/, '')
    return [qs.parse(searchWithoutQuestionMark), search]
  }, [search])
}
