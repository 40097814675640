import qs from 'qs'
import { get as getLodash } from 'lodash'
import { useState } from 'react'
import { PaginationMetaType } from 'types/pagination'
import { get, set } from '../utils/cache'
import getErrorForLevel from './utils/getErrorForLevel'

const HOST = process.env.REACT_APP_BACKEND_URL

export const toCamel = (s: string) => {
  return s.replace(/([-_][a-z0-9])/gi, $1 => {
    return $1
      .toUpperCase()
      .replace('-', '')
      .replace('_', '')
  })
}

export const propertiesToCamel = (obj: any) => {
  const resp: any = {}

  Object.keys(obj).forEach((key: string) => {
    resp[toCamel(key)] = obj[key]
  })

  return resp
}

export const propertiesToCamelDepth = (obj: any) => {
  const resp: any = {}

  Object.keys(obj).forEach(key => {
    resp[toCamel(key)] = obj[key]
    if (typeof obj[key] === 'object' && obj[key] != null) {
      resp[toCamel(key)] = propertiesToCamelDepth(obj[key])
    } else {
      resp[toCamel(key)] = obj[key]
    }
  })

  return resp
}

/**
 * Method to parse data from the Backend. It extract the property attributes,
 * also, it converts the properties from snaked_case to camelCase
 * @param {object} obj element to parse
 * @returns {object} A formatted response
 */
export const propertiesWithoutAttributesField = (obj: any) => {
  const resp: any = {}

  if (typeof obj === 'string') {
    // obj is not an object
    return obj
  }
  Object.keys(obj).forEach(key => {
    const cammelKey = toCamel(key)
    if (Object.keys(obj[key] || {})[0] === 'data') {
      // extract the attributes field, if exists
      if (Array.isArray(obj[key].data)) {
        resp[cammelKey] = obj[key].data.map((item: any) => ({
          ...propertiesWithoutAttributesField(item.attributes || item),
          id: item.id,
        }))
      } else if (obj[key].data) {
        resp[cammelKey] = propertiesWithoutAttributesField(
          getLodash(obj[key], 'data.attributes', obj[key].data),
        )
        resp[cammelKey].id = obj[key].data.id
      }
    } else if (typeof obj[key] === 'object' && obj[key] != null) {
      // go depth
      if (Array.isArray(obj[key])) {
        resp[cammelKey] = obj[key].map(propertiesWithoutAttributesField)
      } else {
        resp[cammelKey] = propertiesWithoutAttributesField(obj[key])
      }
    } else {
      // do nothing
      resp[cammelKey] = obj[key]
    }
  })
  return resp
}

export type genericParseResponseType<T> = {
  data: T[]
  meta: PaginationMetaType
}

export type genericParseResponseOneItemType<T> = {
  data: T
}

/**
 * Method to parse the BE response in a generic way when the data is an Array
 * @param {object} response Response from the BE
 * @param {Array.<object>} response.data Elements from the resource
 * @param {object} response.meta Contains meta information, such the amount of items, and information for the pagination
 */
export const genericParseResponse = <T>(response: {
  data: any[]
  links: any
}): genericParseResponseType<T> => {
  return {
    data: response.data.map(formatBackendResponse),
    meta: {
      totalCount: getLodash(response, 'meta.page.total_count'),
      ...response.links,
    },
  }
}

/**
 * Method to parse the BE response in a generic way when the data is only one item
 */
export const genericParseResponseOneItem = <T>(response: {
  data: any[]
  links: any
}): genericParseResponseOneItemType<T> => {
  return {
    data: formatBackendResponse(response.data),
  }
}

export const formatBackendResponse = (data: any) => {
  const wrappedResponse = { root: { data } }
  return propertiesWithoutAttributesField(wrappedResponse).root
}

export const warningProxyHandler = (message: string) => {
  return {
    // eslint-disable-next-line
    get: function get(obj: any, prop: string) {
      console.warn(message)
      return obj[prop]
    },
  }
}

export const getToken = () => {
  try {
    // @ts-ignore
    return JSON.parse(localStorage.getItem('user')).auth_token
  } catch {
    // console.log('Error on get user')
  }
  return ''
}

export const genericGet = (path: string, filter = {}): Promise<{ data: any; links: any }> => {
  const serializedFilters = qs.stringify(filter, { arrayFormat: 'brackets' })
  // @ts-ignore
  return fetch(`${HOST}${path}${serializedFilters}`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${getToken()}`,
    },
  }).then(ApiErrorHandler)
}

export const genericGetWithCache = (path: string, filter: object = {}, duration?: number) => {
  const cacheKey = `${path}_${JSON.stringify(filter)}`
  const cacheValue = get(cacheKey)

  if (cacheValue) {
    return Promise.resolve(cacheValue)
  }
  const resolver = genericGet(path, filter)

  set(cacheKey, resolver, duration)

  return resolver
}

export interface IApiError extends Error {
  data?: {
    message?: string
    details?: {
      [key: string]: [string]
    }
  }
  requestId?: string | null
  statusCode?: number
  url?: string
}

interface ApiResponse<T> {
  error?: any
  data?: T
}

export const ApiErrorHandler = async <T>(response: Response): Promise<T> => {
  let body: ApiResponse<T> | undefined

  try {
    body = (await response.json()) as ApiResponse<T>
  } catch {
    console.error('Error parsing the response from the server')
  }

  if (![200, 201].includes(response.status)) {
    const errorObj: IApiError = new Error()

    errorObj.name = 'ApiErrorHandler'
    errorObj.statusCode = response.status
    errorObj.requestId = response.headers.get('X-Request-Id')
    errorObj.url = response.url

    if (body) {
      errorObj.data = body.error
    } else if (response.status === 404) {
      // default message for the 404
      errorObj.data = {
        message: 'The URL was not found',
      }
    } else if (response.status === 500) {
      // default message for the 500
      errorObj.data = {
        message: 'There was an internal error',
      }
    }

    throw errorObj
  }
  return body as T
}

/**
 * Method to perserve the structure of the error object from Livechat API
 */
export const ApiErrorHandlerLivechat = async (response: any) => {
  const body = await response.json()
  if (![200, 201].includes(response.status)) {
    const errorObj: IApiError = new Error()
    errorObj.name = 'ApiErrorHandler'
    errorObj.data = {
      details: {
        livechat: [body.error.message],
      },
    }

    throw errorObj
  }
  return body
}

/**
 * Helper function to highlight form errors in Form.Item from antd
 * @param {string} path     Path of the field in the errorObj parameter
 * @param {object} errorObj  Object with the validation errors in the Form
 */
export const getValidationStatus = (path: string, errorObj?: IApiError) => {
  const errorData = errorObj && errorObj.data && errorObj.data.details

  return getErrorForLevel(path.split('.'), errorData)
}

/**
 * Method to list all the error messages from the error object created by ApiErrorHandler middleware
 * This method is usefull to show an alert with all the validation errors from the backend
 */
export const getErrorMessage = (error: IApiError) => {
  const errorMessages: string[] = []
  const errorKeys = Object.keys(getLodash(error, 'data.details', {}))
  errorKeys.forEach(key => {
    // @ts-ignore
    errorMessages.push(...(error?.data?.details[key] || []))
  })
  return errorMessages
}

export const prepareFilters = (filters: any) => {
  const sanitizedFilters = Object.keys(filters).reduce((acc: any, key: string) => {
    const filter = filters[key]
    if (filter) {
      acc[key] = filter
    }
    return acc
  }, {})
  return sanitizedFilters
}

export function useForceUpdate() {
  const [, setValue] = useState(0) // integer state
  return () => setValue(value => value + 1) // update the state to force render
}

export const removeEmptyProperties = (obj: any) => {
  return Object.entries(obj)
    .filter(([, v]) => v != null)
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})
}
