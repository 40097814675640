import { useState } from 'react'
import NProgress from 'nprogress'
import '../assets/styles/nprogress/style.module.scss'

let paralelLoadingCouter = 0

const updateLoadingCounter = value => {
  if (value) {
    paralelLoadingCouter += 1
  } else {
    paralelLoadingCouter -= 1
  }
  paralelLoadingCouter = paralelLoadingCouter > 0 ? paralelLoadingCouter : 0
}

export default defaultValue => {
  const [value, setValue] = useState(defaultValue)

  if (defaultValue) {
    console.warn('defaultValue cant initialize in true')
  }

  const decoratedSetValue = newValue => {
    updateLoadingCounter(newValue)

    if (paralelLoadingCouter > 0) {
      NProgress.inc()
    } else if (paralelLoadingCouter === 0) {
      NProgress.done()
    }
    setValue(newValue)
  }

  return [value, decoratedSetValue]
}
