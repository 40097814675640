import React, { useContext, createContext } from 'react'
import useLocalStorage from 'utils/useLocalStorage'

export const StoreContext = createContext({})

class CalmResizeObserver extends ResizeObserver {
  constructor(callback) {
    super((entries, observer) => {
      requestAnimationFrame(() => {
        callback(entries, observer)
      })
    })
  }
}
/**
 * This overwride is to prevent the error "ResizeObserver - loop limit exceeded"
 * This error is harmless, so it is safe to wrap the ResizeObserver class to dismiss that exception
 * https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded/50387233#50387233
 */
window.ResizeObserver = CalmResizeObserver

export const LayoutContext = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useLocalStorage('isMenuOpen', false)

  return (
    <StoreContext.Provider
      value={[
        {
          isMenuOpen,
        },
        {
          setIsMenuOpen,
        },
      ]}
    >
      {children}
    </StoreContext.Provider>
  )
}

const useLayout = () => useContext(StoreContext)

export default useLayout
