import { notification } from 'antd'

function onTokenExpire(jwtToken) {
  return new Promise(resolve => {
    // Parse the JWT token to extract the expiration date
    const tokenData = JSON.parse(atob(jwtToken.split('.')[1])) // Assuming the payload is base64 encoded

    // Extract the expiration timestamp from the token data
    const expirationTimestamp = tokenData.exp * 1000 // Convert to milliseconds

    // Calculate the remaining time until expiration
    const currentTime = Date.now()
    const timeUntilExpiration = expirationTimestamp - currentTime

    // Set up a timeout to resolve the Promise when the token expires
    if (timeUntilExpiration > 0) {
      setTimeout(() => {
        resolve()
      }, timeUntilExpiration)
    }
  })
}

export default onTokenExpire
