import useUser from 'store/users/hook'
import { useState } from 'react'
import { relativeToAbsoluteDatesMapper } from 'components/Filters/filters/utils'
import { parseResponse, getOmniCenterChatOrder } from './utils'
import { genericGet } from '../utils'

const resourceBasePath = '/v1/chats?'

const useChats = (filters, defaultSort) => {
  const [{ user }] = useUser()
  const [sort, setSort] = useState(defaultSort)

  const getData = (size = 10, offset = 0) => {
    // Prepare filter to make request
    const formattedFilters = JSON.parse(JSON.stringify(filters))

    // format for custom date filter

    if (formattedFilters.date) {
      // if the date is an array, then is the custom selection
      if (!Array.isArray(formattedFilters.date)) {
        // Date filter is a special one
        // eslint-disable-next-line
        const absoluteDates = relativeToAbsoluteDatesMapper[filters.date]()
        // eslint-disable-next-line
        formattedFilters.date_from = absoluteDates[0]
        // eslint-disable-next-line
        formattedFilters.date_to = absoluteDates[1]
      } else {
        ;[formattedFilters.date_from, formattedFilters.date_to] = formattedFilters.date
      }
      delete formattedFilters.date
    }

    return genericGet(resourceBasePath, {
      filter: formattedFilters,
      sort: sort.column ? `${sort.order === 'descend' ? '-' : ''}${sort.column}` : '',
      page: { size, after: offset - 1 },
    })
      .then(parseResponse)
      .then(resp => {
        // Extra fields for chats list
        resp.data.forEach(chat => {
          const now = new Date()
          chat.order = getOmniCenterChatOrder(chat, user)
          chat.absoluteDateUntilReservationExpires = new Date(
            now.setSeconds(now.getSeconds() + chat.secondsUntilReservationExpires),
          )
        })
        return resp
      })
  }

  return [
    {
      filters,
      sort,
    },
    {
      getData,
      setSort,
    },
  ]
}

export default useChats
