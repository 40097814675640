import React, { useState, useEffect, createContext, useContext, ReactChild } from 'react'
import PropTypes from 'prop-types'
import { IntlProvider } from 'react-intl'
import { get } from 'lodash'
import moment from 'moment'
import 'moment/locale/es'
import 'moment/locale/pt-br'
import ReactDOM from 'react-dom'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom'

// @ts-ignore
import detectBrowserLanguage from 'detect-browser-language'
import { advaiceBdcPendingConfirmation } from 'store/chatCenter/utils'
import onTokenExpire from 'utils/onTokenExpire'
import { getToken } from 'store/utils'
import useLocalStorage from 'utils/useLocalStorage'
import { UserType } from 'types/users'

import { FlagsProvider } from 'flagged'

import messagesEn from '../../translations/en.json'
import messagesEs from '../../translations/es.json'
import messagesPt from '../../translations/pt.json'
import useLoadingState from '../../utils/useLoadingState'
import {
  getCurrentUser,
  askResetPassword,
  setNewPassword as setNewPasswordApi,
  getAccounts as getAccountsApi,
  login as loginApi,
} from './api'
import { parseUser, getMenuData, parseStats, parseSettings, parseKnowledgeBaseStats } from './utils'

type UserHookType = [
  {
    user: UserType
    menu: any
    loading: boolean
    locale: string
    errors: any
  },
  {
    login: (email: string, password: string) => void
    logout: () => void
    resetPassword: (email: string) => Promise<any>
    setNewPassword: (password: string, token: string) => Promise<unknown>
    refreshUser: () => void
  },
]

const initialState = [
  {
    user: {},
    menu: [],
    loading: false,
    locale: 'en_US',
    errors: [],
  },
  {
    login: () => {},
    logout: () => {},
    resetPassword: () => {},
    setNewPassword: () => {},
    refreshUser: () => {},
  },
]

// @ts-ignore
export const StoreContext = createContext<UserHookType>(initialState)

const messages = {
  en: messagesEn,
  es: messagesEs,
  pt: messagesPt,
}

const getLocaleForMoment = (locale: string) => {
  let momentLocale = locale
  if (locale === 'pt') {
    momentLocale = 'pt-br'
  }
  return momentLocale
}

const getAccount = async (id: number) => {
  const accountData: any = {}

  const { data } = await getAccountsApi(id)

  if (data) {
    accountData.id = id
    accountData.creditsAvailable = data.attributes.chat_credits_available
    accountData.stats = parseStats(data.attributes.stats.data)
    accountData.settings = parseSettings(data.attributes.settings)
    accountData.analyticsUrl = data.attributes.analytics_url
    accountData.knowledgeBaseStats = parseKnowledgeBaseStats(
      data.attributes.knowledge_base_stats.data,
    )
    accountData.type = data.attributes.type

    // subscriptions
    accountData.automaticRenewal = data.attributes.automatic_renewal
    accountData.automaticRenewalTermsAccepted =
      data.attributes.settings.subscriptions.automatic_renew_terms_accepted
    accountData.automaticRenewalPackageType =
      data.attributes.settings.subscriptions.automatic_renew_package_type
    accountData.automaticRenewalMethodId =
      data.attributes.settings.subscriptions.automatic_renew_stripe_payment_method_id

    accountData.pendingTypeChangeRequest = data.attributes.pending_type_change_request
    accountData.billingType = data.attributes.billing_type
  }
  return accountData
}

export const UserContext = ({ children }: { children: ReactChild }) => {
  const history = useHistory()
  // @ts-ignore
  const [user, setUser] = useState<UserType | undefined>({})
  const [menu, setMenu] = useState([])
  const [errors, setErrors] = useState([])
  const [loading, setLoading] = useLoadingState(true)
  const [initialization, setInitialization] = useState(true)
  const [refreshIdentify, setRefreshIdentify] = useState<number>()
  const [userStorage, setUserStorage] = useLocalStorage('user')

  useEffect(() => {
    setLoading(true)
    getCurrentUser()
      .then(async resp => {
        let acountData

        if (get(resp, 'data.attributes.account.data.id')) {
          acountData = await getAccount(resp.data.attributes.account.data.id)
          resp.data = {
            ...resp.data,
            ...acountData,
          }
        }
        const formattedUser = parseUser(resp.data)
        // @ts-ignore
        setUser({
          ...formattedUser,
          // @ts-ignore
          account: acountData || formattedUser.account,
        })
        setLoading(false)
        setInitialization(false)
      })
      .catch(e => {
        console.log('Error on get current User', e)
        setLoading(false)
        logout()
      })
    // eslint-disable-next-line
  }, [userStorage, refreshIdentify])

  useEffect(() => {
    const token = getToken()
    if (token) {
      onTokenExpire(token).then(() => {
        localStorage.setItem('redirect_reason', JSON.stringify({ message: 'Token expired' }))
        setUserStorage('')
        window.location.href = '/#/system/login'
      })
    }
  }, [userStorage])

  const browserLanguage = detectBrowserLanguage().split('-')[0] || 'en'
  const locale = user && user.locale ? user.locale : browserLanguage

  const momentLocale = getLocaleForMoment(locale)

  // Temporally do not use locale for Spanish because of bug in thousands separator
  const notSpanishlocale = locale === 'es' ? 'pt-br' : locale

  moment.locale(momentLocale)

  useEffect(() => {
    if (user) {
      // @ts-ignore
      const newMenu: any = getMenuData(user.designId, user.permissions, messages[locale])
      setMenu(newMenu)

      const pendingQuestions = get(user, 'account.knowledgeBaseStats.pendingReviewEntries')
      if (pendingQuestions) {
        advaiceBdcPendingConfirmation(pendingQuestions)
      }
      // @ts-ignore
      FS.identify(user.email, {
        email: user.email,
        name: user.displayName,
        // type: user.userType,
      }) // Add identify to Fullstory service
    } else {
      // Clean the FS identify if the user is not logged in
      // @ts-ignore
      FS.identify(false)
    }
  }, [locale, user])

  const resetPassword = (email: string) => {
    return askResetPassword(email)
  }

  const setNewPassword = (password: string, token: string) => {
    return setNewPasswordApi(password, token)
  }

  const refreshUser = () => {
    setRefreshIdentify(Math.random())
  }

  const login = (email: string, password: string) => {
    setLoading(true)
    setErrors([])

    loginApi(email, password)
      .then(sessionData => {
        if (!sessionData.data) {
          ReactDOM.unstable_batchedUpdates(() => {
            // @ts-ignore
            setErrors([sessionData.error.message])
            setLoading(false)
          })
          return
        }

        setUserStorage({
          ...sessionData.data.attributes,
          email,
        })

        const hashParamObject = (window.location.hash.split('?')[1] || '')
          .split('&')
          .reduce((acc, param) => {
            const [property, value] = param.split('=')
            return { ...acc, [property]: decodeURIComponent(value) }
          }, {})

        // @ts-ignore
        if (hashParamObject.redirect) {
          history.push({
            // @ts-ignore
            pathname: `/${hashParamObject.redirect}`,
          })
        } else {
          history.push('/')
        }

        refreshUser()
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const logout = () => {
    setUserStorage('')
    // Clear omnicenter filter data
    localStorage.removeItem('omnicenter_filters')
    localStorage.removeItem('omnicenter_sort')
    setUser(undefined)
  }

  return (
    <StoreContext.Provider
      value={[
        {
          // @ts-ignore
          user,
          menu,
          loading: loading && initialization,
          locale,
          errors,
        },
        {
          login,
          logout,
          resetPassword,
          setNewPassword,
          refreshUser,
        },
      ]}
    >
      {!loading && !initialization && <Helmet htmlAttributes={{ lang: locale }} />}
      <IntlProvider
        locale={notSpanishlocale}
        // @ts-ignore
        messages={messages[locale]}
      >
        <FlagsProvider features={(user || {}).permissions || {}}>{children}</FlagsProvider>
      </IntlProvider>
    </StoreContext.Provider>
  )
}

UserContext.propTypes = {
  children: PropTypes.node.isRequired,
}

const useUser = () => useContext(StoreContext)

export default useUser
