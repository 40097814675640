import DESIGNS from 'utils/designs'

export const SECTIONS = {
  ACCOUNTS: 'accounts',
  ANALYTICS: 'analytics',
  CAPACITY_PLANNING: 'capacityPlannig',
  CONFIGURATION_MELI_REDIRECT: 'config',
  CONFIGURATION: 'configuration',
  WORKSPACE_GROUP: 'workspaceGroup',
  KNOWLEDGE_BASE: 'knowledgeBase',
  LEGACY_CONFIGURATION: 'legacyConfiguration',
  MARKETING: 'marketing',
  MY_PLAN: 'myPlan',
  MY_TEAM: 'myTeam',
  OMNICENTER: 'omnicenter',
  OPPORTUNITIES: 'opportunities',
  TICKETS: 'tickets',
  WORKSPACES: 'workspaces',
}

// sections used in both designs
const SHARED_SECTIONS = [
  SECTIONS.ACCOUNTS,
  SECTIONS.ANALYTICS,
  SECTIONS.CAPACITY_PLANNING,
  SECTIONS.WORKSPACE_GROUP,
  SECTIONS.KNOWLEDGE_BASE,
  SECTIONS.MY_PLAN,
  SECTIONS.OPPORTUNITIES,
]

// gradually we will add new sections in the yoda design
export const SECTIONS_DESIGNS = {
  [DESIGNS.YODA]: [
    SECTIONS.CONFIGURATION_MELI_REDIRECT,
    SECTIONS.CONFIGURATION,
    SECTIONS.MARKETING,
    SECTIONS.MY_TEAM,
    SECTIONS.OMNICENTER,
    SECTIONS.TICKETS,
    SECTIONS.WORKSPACES,
    ...SHARED_SECTIONS,
  ],
  [DESIGNS.LEGACY]: [SECTIONS.LEGACY_CONFIGURATION, ...SHARED_SECTIONS],
}
