import { ContextType } from 'types/notifications'
import { chatThreadEventCreatedDataType } from '../types'
import { sortChatList } from '../utils'

export const omniCenterChatThreadEventCreatedHandler = (
  context: ContextType,
  data: chatThreadEventCreatedDataType,
) => {
  const { chats, setChats, chatDetail, setChatDetail, sort, chatStore } = context

  if (chatDetail?.id === `${data.chat.id}`) {
    const thread = chatDetail.threads.find(
      t => `${t.id}` === `${data.chatThreadEvent.chatThreadId}`,
    )
    if (thread) {
      // check if the event was already added
      // this could happen if the sender of the event is the same as the current user
      const threadEvent = thread.events.find(
        threadE => threadE.uuid === `${data.chatThreadEvent.uuid}`,
      )

      if (threadEvent) {
        // Replace the old value with the new one
        thread.events.splice(thread.events.indexOf(threadEvent), 1, data.chatThreadEvent)
        setChatDetail({
          ...chatDetail,
        })
      } else {
        // Event not found in the thread
        thread.events.push(data.chatThreadEvent)
        setChatDetail({
          ...chatDetail,
          unreadMessagesCount: chatDetail.unreadMessagesCount + 1,
        })
      }
    } else {
      // Thread was not found
    }
  } else {
    // Invalidate the data from local cache
    chatStore.invalidate(data.chat.id)
  }

  const chatListItem = chats.find(chat => chat.id === `${data.chat.id}`)
  if (chatListItem) {
    // always add a new message, later on, the presentational layer
    // will define if the new event is visible for the current user
    // if so, then it will trigger a markEventsAsSeen and remove the
    // unreadMessagesCount locally and remote.
    chatListItem.unreadMessagesCount += 1

    chatListItem.lastInteractionAt = data.chatThreadEvent.timestamp

    setChats(sortChatList([...chats], sort))
  }
}
