import { NotificationType } from 'types/notifications'

export const APPLICATION_EVENTS = {
  CHAT_ASSIGNED: 'chat_assigned',
  CHAT_THREAD_ASSIGNED: 'chat_thread_assigned',
  CHAT_THREAD_CLOSED: 'chat_thread_closed',
  CHAT_THREAD_EVENT_CREATED: 'chat_thread_event_created',
  CHAT_THREAD_EVENT_UPDATED: 'chat_thread_event_updated',
  CHAT_THREAD_OPEN: 'chat_thread_open',
  CHAT_THREAD_TAGGED: 'chat_thread_tagged',
  CHAT_UNASSIGNED: 'chat_unassigned',
  NEW_CHAT_THREAD_EVENT: 'new_chat_thread_event',
  CHAT_CLOSED: 'chat_closed',
  CHAT_OPEN: 'chat_open',
  USER_CONNECTED: 'user_connected',
  USER_DISCONNECTED: 'user_disconnected',
} as const

export const NOTIFICATION_TYPE = {
  NEW_CHAT: 'new_chat',
  NEW_MESSAGE: 'new_message',
  UPDATE_CHAT: 'update_chat',
  CLOSE_CHAT: 'close_chat',
  GENERIC: 'generic',
} as const

export const NOTIFICATION_CHANNEL = {
  WEB: 'web',
  DESKTOP: 'desktop',
} as const

export const notificationsAudios: {
  [key in NotificationType]: HTMLAudioElement | null
} = {
  [NOTIFICATION_TYPE.NEW_CHAT]: new Audio('/resources/sounds/new-chat-notification.mp3'),
  [NOTIFICATION_TYPE.NEW_MESSAGE]: new Audio('/resources/sounds/notification.mp3'),
  [NOTIFICATION_TYPE.UPDATE_CHAT]: new Audio('/resources/sounds/notification.mp3'),
  [NOTIFICATION_TYPE.CLOSE_CHAT]: null,
  [NOTIFICATION_TYPE.GENERIC]: null, // The default audio is 'no audio'
} as const
