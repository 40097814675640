import { notification } from 'antd'
import { notificationsAudios, NOTIFICATION_CHANNEL } from 'enums/notifications'
import { ReactNode } from 'react'
import { NotificationChannelType, NotificationType } from 'types/notifications'

/**
 *
 * @param channel the channel where the notification should be shown
 * @param type it will affect the sound that the notification generates
 * @param title if title is null then show only an audio notification
 */
export const notify = (
  channel: NotificationChannelType,
  type: NotificationType,
  title: string | null,
  content: string | ReactNode,
  notificationClickAction: string | undefined,
) => {
  // Reproduce Audio
  const notificationAudio = notificationsAudios[type]
  if (notificationAudio) {
    notificationAudio.play().catch((error: Error) => {
      console.log('Chrome cannot play sound without user interaction first', error)
    })
  }

  if (title !== null) {
    if (channel === NOTIFICATION_CHANNEL.WEB) {
      notification.info({
        message: title,
        description: content,
      })
    } else if (channel === NOTIFICATION_CHANNEL.DESKTOP) {
      // check the permisions for showing desktop notifications
      if (Notification.permission !== 'granted') Notification.requestPermission()
      else {
        window.navigator.serviceWorker.ready.then(registration => {
          registration.showNotification(title, {
            icon: 'resources/images/chat-center-logo.png',
            body: content as string,
            data: {
              action: 'open',
              url: notificationClickAction,
            },
          })
        })
      }
    }
  }
}
