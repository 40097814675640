import { ApiErrorHandler, getToken } from 'store/utils'

const HOST = process.env.REACT_APP_BACKEND_URL

export const createCustomer = ({
  countryCode,
  email,
  firstName,
  workspaceId,
  lastName,
  phone,
  source,
  tagIds,
}) => {
  return fetch(`${HOST}/v1/customers`, {
    method: 'POST',
    headers: {
      'content-type': 'application/vnd.api+json',
      authorization: getToken(),
    },
    body: JSON.stringify({
      data: {
        attributes: {
          country_code: countryCode,
          email,
          first_name: firstName,
          workspace_id: workspaceId,
          last_name: lastName,
          phone,
          source,
          tag_ids: tagIds,
        },
      },
    }),
  }).then(ApiErrorHandler)
}

export const updateCustomer = ({
  id,
  countryCode,
  email,
  firstName,
  workspaceId,
  lastName,
  phone,
  source,
  tagIds,
  isMarketingOptIn,
  isMarketingOptOut,
}) => {
  return fetch(`${HOST}/v1/customers/${id}`, {
    method: 'PUT',
    headers: {
      'content-type': 'application/vnd.api+json',
      authorization: getToken(),
    },
    body: JSON.stringify({
      data: {
        attributes: {
          country_code: countryCode,
          email,
          first_name: firstName,
          workspace_id: workspaceId,
          last_name: lastName,
          phone,
          source,
          tag_ids: tagIds,
          is_marketing_opt_in: isMarketingOptIn,
          is_marketing_opt_out: isMarketingOptOut,
        },
      },
    }),
  }).then(ApiErrorHandler)
}

export const bulkImport = (base64Data, workspaceId) => {
  return fetch(`${HOST}/v1/customers/import`, {
    method: 'POST',
    headers: {
      'content-type': 'application/vnd.api+json',
      authorization: getToken(),
    },
    body: JSON.stringify({
      data: {
        attributes: {
          csv: base64Data,
          workspace_id: workspaceId,
        },
      },
    }),
  }).then(ApiErrorHandler)
}
