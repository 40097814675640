import { ContextType } from 'types/notifications'
import { chatThreadOpenDataType, newChatThreadEventDataType } from './types'

export const omniNewChatThreadEventHandler = (
  context: ContextType,
  data: newChatThreadEventDataType,
) => {
  console.log('data', data)
  // TODO check the filter state to see if is needed to add the chat in the current view
}

export const omniChatThreadOpenHandler = (context: ContextType, data: chatThreadOpenDataType) => {
  const { chatDetail, setChatDetail } = context

  if (chatDetail?.id === `${data.chat.id}`) {
    const threadItem = chatDetail.threads.find(ti => ti.id === data.chatThread.id)
    if (threadItem) {
      // update the thread
      chatDetail.threads[chatDetail.threads.indexOf(threadItem)] = data.chatThread
    } else {
      // add the thread
      chatDetail.threads.push(data.chatThread)
    }
    setChatDetail({
      ...chatDetail,
    })
  }
}
