import { ContextType } from 'types/notifications'
import { chatThreadClosedDataType } from '../types'

export const omniCenterChatThreadClosedHandler = (
  context: ContextType,
  data: chatThreadClosedDataType,
) => {
  const { chatDetail, setChatDetail } = context

  if (chatDetail?.id === `${data.chat.id}`) {
    const threadItem = chatDetail.threads.find(ti => ti.id === data.chatThread.id)
    if (threadItem) {
      // update the thread
    } else {
      // do nothing
    }
    setChatDetail({
      ...chatDetail,
    })
  }
}
